import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardImgOverlay,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { XMasonry, XBlock } from "react-xmasonry";
import InfiniteScroll from "react-infinite-scroll-component";
import Spacer from "../../components/spacer/Spacer";

// css
import styles from "./Bands.module.css";

// api function
import { getBands } from "../../api_functions/bands_api";

const Bands = () => {
  const [bands, setBands] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredBands, setFilteredBands] = useState([]);
  const [displayedBands, setDisplayedBands] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getBands().then((res) => {
      setBands(res);
    });
  }, []);

  const loadMoreBands = () => {
    if (displayedBands.length >= filteredBands.length) {
      setHasMore(false);
      return;
    }
    setDisplayedBands(filteredBands.slice(0, displayedBands.length + 25));
  };

  const filterBands = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setHasMore(true);
    setFilteredBands(
      bands.filter((band) =>
        band.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, bands]);

  useEffect(() => {
    setDisplayedBands(filteredBands.slice(0, 25));
  }, [filteredBands]);

  return (
    <div className={styles.mainContainer}>
      {/* search bar */}
      <div className={styles.searchBar}>
        <FormGroup floating>
          <Input
            type="text"
            name="search"
            id="searchBar"
            placeholder="Search"
            onChange={(e) => filterBands(e)}
          />
          <Label for="searchBar" className={styles.searchlabel}>
            <img src="/static-images/search.svg" alt="search icon" />
            {"   "}
            Suche eine bestimmte Band
          </Label>
        </FormGroup>
      </div>
      <br />
      {displayedBands && (
        <InfiniteScroll
          dataLength={displayedBands.length}
          next={loadMoreBands}
          hasMore={hasMore}
          // loader={}
          endMessage={<p style={{ textAlign: "center" }}></p>}
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 524: 1, 525: 2, 768: 3, 1025: 4 }}
          >
            <Masonry>
              {displayedBands.map((band) => (
                <Card className={styles.bandCard} key={band.name}>
                  <CardImg
                    src={band.image}
                    alt={band.name}
                    className={styles.bandImg}
                  />
                  <CardImgOverlay className={styles.bandImgOverlay}>
                    <CardTitle className={styles.bandName}>
                      <a
                        className={styles.bandNameLink}
                        href={band.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {band.name}
                      </a>
                    </CardTitle>
                  </CardImgOverlay>
                </Card>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
        // if displayedBands is empty or less than 25, add padding
      )}

      {/* {filteredBands ? (
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            275: 1,
            576: 2,
            768: 2,
            992: 3,
            1200: 4,
          }}
        >
          <Masonry gutter="2rem">
            {filteredBands.map((band) => (
              <Card className={styles.bandCard} key={band.name}>
                <CardImg
                  src={band.image}
                  alt={band.name}
                  className={styles.bandImg}
                />
                <CardImgOverlay className={styles.bandImgOverlay}>
                  <CardTitle className={styles.bandName}>
                    <a
                      className={styles.bandNameLink}
                      href={band.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {band.name}
                    </a>
                  </CardTitle>
                </CardImgOverlay>
              </Card>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <div className={styles.loading}>Loading...</div>
      )} */}
    </div>
  );
};

export default Bands;
