import axios from "axios";

export const getEvents = async (upcoming = true, past = false, number) => {
  // build the query string
  let query = "?";
  if (upcoming) {
    query += "upcoming";
  } else if (past) {
    query += "&past";
  }
  if (number) {
    query += `&number=${number}`;
  }

  // send the request
  return await axios
    .get(`/api/events/${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getHighlights = async () => {
  return await axios
    .get(`/api/highlights/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getEvent = async (event_url) => {
  // build the query string
  let query = "?";
  if (event_url) {
    query += `event_url=${event_url}`;
  }

  // send the request
  return await axios
    .get(`/api/event/${query}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createEvent = async (eventData) => {
  let formData = new FormData();
  for (let key in eventData) {
    if (key === "image") {
      formData.append("image", eventData[key], eventData[key].name);
    } else {
      formData.append(key, eventData[key]);
    }
  }
  return await axios
    .post("/api/events/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
};
