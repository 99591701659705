import React, { useState, useEffect } from "react";
import { Row, Col, NavLink } from "reactstrap";

// css
import styles from "./EventItem.module.css";

const EventItem = ({ event }) => {
  const date = new Date(event.start_date);
  const month = date.toLocaleString("de", { month: "short" });
  const day = date.toLocaleString("de", { day: "2-digit" });
  const time = date.toLocaleString("de", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const date_mobile = date.toLocaleString("de", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const cancelled = event.status === "CAN";

  const description_text = event.description
    .replace(/<[^>]*(>|$)| |‌|»|«|>/g, " ")
    .replace(/&/g, "&");

  return (
    <NavLink href={`/event/${event.event_url}`} className={styles.navLink}>
      <Row className={`no-gutters ${styles.row}`}>
        <Col
          lg="2"
          md="3"
          sm="3"
          className={`${styles.image_container}  ${
            cancelled && styles.colCancelled
          }`}
        >
          <img src={event.thumbnail} alt="event" className={styles.image} />
        </Col>
        <Col
          lg="8"
          md="7"
          sm="9"
          className={`${styles.text_container} 
          }`}
        >
          <div className={styles.wrapper}>
            {cancelled && (
              <span className={styles.cancel_span}>
                Das Konzert wurde abgesagt
              </span>
            )}
            <div
              className={`${styles.title} ${cancelled && styles.colCancelled}`}
            >
              {event.title}
            </div>
            <div
              className={`${styles.description} ${
                cancelled && styles.colCancelled
              }`}
              dangerouslySetInnerHTML={{ __html: description_text }}
            />
            <div className={styles.moreButtonContainer}>
              <a
                className={styles.more_button}
                href={`/event/${event.event_url}`}
              >
                weiterlesen
              </a>
            </div>
          </div>
        </Col>
        <Col
          md="2"
          sm="12"
          className={`${styles.date_container} ${
            cancelled && styles.colCancelled
          } ${cancelled && styles.cancelled}`}
        >
          <span className={styles.day}>{day}</span>
          <span className={styles.month}>{month}</span>
          <span className={styles.time}>{time}</span>
        </Col>
        <Col
          md="2"
          sm="12"
          className={`${styles.date_container_mobile}  ${
            cancelled && styles.colCancelled
          }`}
        >
          <span
            className={`${styles.date_mobile} ${cancelled && styles.cancelled}`}
          >
            {date_mobile}
          </span>
        </Col>
      </Row>
      {/* </div> */}
    </NavLink>
  );
};

export default EventItem;
