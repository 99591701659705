import React, { useState, useEffect } from "react";
import { getEvents, getHighlights } from "../../api_functions/events_api";
import { Parallax } from "react-scroll-parallax";

// css
import styles from "./Homepage.module.css";

// components
import Quote from "../../components/container/quote/Quote";
import Spacer from "../../components/spacer/Spacer";
import PlainList from "../../components/events/eventLists/plainList/PlainList";
import FullImageContainer from "../../components/container/fullImageContainer/FullImageContainer";
import {
  Button1,
  Button2,
  Button3,
  DoubleButton,
  ArrowButton,
} from "../../components/buttons/Buttons";
import Map from "../../components/map/Map";
import { SubHeadline } from "../../components/Headlines";

// Homepage Contents
import FirstContainer from "../../components/container/homepage/FirstContainer";
import SecondContainer from "../../components/container/homepage/SecondContainer";
import ThirdContainer from "../../components/container/homepage/ThirdContainer";
import FourthContainer from "../../components/container/homepage/FourthContainer";
import { Col, Row } from "reactstrap";

const Homepage = () => {
  const [events, setEvents] = useState(null);
  const [highlights, setHighlights] = useState(null);

  // get the next events
  useEffect(() => {
    getEvents(true, true, 5).then((data) => {
      setEvents(data);
    });
    getHighlights().then((data) => {
      setHighlights(data);
    });
    // eslint-disable-next-line
  }, []);

  // const

  return (
    <div className={styles.mainContainer}>
      {/* full width image */}
      <div className={styles.fullWidthImageContainer}>
        <img
          src={"/static-images/homepage/main.jpg"}
          alt="homepage"
          className={styles.mainImage}
        />
        <div className={styles.mainImageOverlay}></div>
        <Parallax translateY={["0px", "-400px"]}>
          <div className={styles.mainImageText}>
            <h1 className={styles.mainImageTitle}>Manitu Liveclub</h1>
            <h2 className={styles.mainImageSubtitle}>Livemusik seit 1999</h2>
          </div>
        </Parallax>
      </div>
      <div className={styles.contentContainer}>
        {/* <Spacer height="3rem" /> */}
        {/* Veranstaltungen */}
        {/* <div className="contentContainer"> */}
        {events && <PlainList events={events} />}
        {/* </div> */}

        {/* Feiern im Manitu */}
        <FirstContainer events={highlights} />

        {/* Musikalische Vielfalt */}
        <SecondContainer />

        {/* Angebot und Style */}
        <FullImageContainer
          imagePath={"/static-images/homepage/3.jpg"}
          title="Uvergessliches Ambiente und einzigartiges Angebot"
          texts={[
            'Bei der Gestaltung des MANITU war von Anfang an klar, dass es ein besonderer Ort sein sollte, an dem man vom Alltag abschalten und sich wohlfühlen kann. Die Ausstattung aus viel Holz und liebevollen Details schafft ein einzigartiges Ambiente, das oft als "Southern Rock Saloon" oder "Barrel House" bezeichnet wird.',
            "Aber natürlich möchten wir den Gästen nicht nur ein besonderes Ambiente bieten.",
            "Das Getränkeangebot im MANITU wird ständig verändert oder erweitert. Zurzeit können Sie aus über 570 verschiedenen Getränken wählen. Darunter befinden sich 36 Sorten Bier- und Biercocktails, 30 Sorten Rum und über 350 Sorten Whisky aus aller Welt.",
          ]}
        />
        {/* Und da der Schwerpunkt auf Whisky und Rum liegt, gibt es bei uns auch die äußerst gefragten „Whisky-Lounge-Abende“. Genießen in gemütlicher Atmosphäre, bei bester Livemusik – was will man mehr! */}
        {/*  Natürlich bekommen Sie auch reichlich Cocktails, Longdrinks und vielen mehr! */}

        {/* Private Feiern */}
        <ThirdContainer />

        {/* Unsere Story */}
        <FourthContainer />

        <Spacer height="3rem" />
        <Quote />

        <div className="contentContainer">
          <Row>
            <Col xs={12} md={4}>
              <Row>
                <Col xs={12} sm={6} md={12}>
                  <SubHeadline
                    title="Adresse"
                    level="4"
                    position="left"
                    bottomSpace="0"
                    color="var(--text-color)"
                  />
                  <p className={styles.text}>
                    Frankfurter Straße 135,
                    <br />
                    03149 Forst (Lausitz)
                  </p>
                </Col>
                <Col xs={12} sm={6} md={12}>
                  <SubHeadline
                    title="Kontakt"
                    level="4"
                    position="left"
                    bottomSpace="0"
                    color="var(--text-color)"
                  />
                  <p className={styles.text}>
                    Telefon: +49 3562 665274
                    <br />
                    Email:{" "}
                    <a
                      className={styles.mail_a}
                      href="mailto:info@manitu-forst.de"
                    >
                      info@manitu-forst.de
                    </a>
                  </p>
                </Col>
                {/* <Col xs={12} sm={6} md={12}>
                  <SubHeadline
                    title="Öffnungszeiten"
                    level="4"
                    position="left"
                    bottomSpace="0"
                    color="var(--text-color)"
                  />
                  <p className={styles.text}>
                    Montag - Freitag: 8:00 - 17:00 Uhr
                    <br />
                    Samstag: 8:00 - 12:00 Uhr
                  </p>
                </Col> */}
                <Col xs={12} sm={6} md={12}>
                  <SubHeadline
                    title="Social Media"
                    level="4"
                    position="left"
                    bottomSpace="0"
                    color="var(--text-color)"
                  />
                  <p className={styles.social_media}>
                    <a href="https://www.facebook.com/people/Manitu/100063475542914/">
                      <img
                        className={styles.icon}
                        style={{ margin: "0 0.5rem" }}
                        src="/static-images/facebook_logo.png"
                        alt="facebook"
                        width={30}
                        height={30}
                      />
                    </a>
                    <a href="https://www.instagram.com/manitu.liveclub/">
                      <img
                        className={styles.icon}
                        style={{ margin: "0 0.5rem" }}
                        src="/static-images/instagram_logo.png"
                        alt="instagram"
                        width={30}
                        height={30}
                      />
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={8}>
              <Map full={true} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
