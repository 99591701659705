import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Button } from "reactstrap";

// css
import styles from "./Buttons.module.css";
import "./Buttons.css";

const Button1 = (props) => {
  return (
    <Button
      className={`${styles.button_1} ${styles.s}`}
      onClick={props.onClick}
    >
      {props.value}
    </Button>
  );
};

const Button2 = (props) => {
  return (
    <Button
      className={`${styles.button_2} ${styles.s}`}
      onClick={props.onClick}
    >
      {props.value}
    </Button>
  );
};

const Button3 = (props) => {
  return (
    <Button
      className={`${styles.button_3} ${styles.s}`}
      onClick={props.onClick}
    >
      {props.value}
    </Button>
  );
};

const DoubleButton = (props) => {
  return (
    <a
      href={props.url}
      className={`doubleButton ${!props.dark && "doubleButton-light"} nav-link`}
    >
      <div className="bottom"></div>

      <div className="top">
        <div className="label">{props.value}</div>

        <div className="doubleButton-border doubleButton-border-left"></div>
        <div className="doubleButton-border doubleButton-border-top"></div>
        <div className="doubleButton-border doubleButton-border-right"></div>
        <div className="doubleButton-border doubleButton-border-bottom"></div>
      </div>
    </a>
  );
};

const ArrowButton = (props) => {
  return (
    <div className="arrowButtonContainer">
      <Link
        className="arrowButton firstStage"
        to={props.url}
        style={{ "--arrow-width": props.width }}
      >
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="arrowButton-text">{props.value}</span>
      </Link>
    </div>
  );
};

export { Button1, Button2, Button3, DoubleButton, ArrowButton };
