import React, { useEffect, useState } from "react";

// css
import "./Container.css";

// components
import CustomCarousel from "../../carousel/CustomCarousel";
import ListItem from "../../events/listItem/ListItem";
import { Row } from "reactstrap";
import { SubHeadline } from "../../Headlines";

const imagePaths = [
  "/static-images/homepage/1. feiern/1.jpg",
  "/static-images/homepage/1. feiern/2.jpg",
  "/static-images/homepage/1. feiern/3.jpg",
  "/static-images/homepage/1. feiern/4.jpg",
];

const FirstContainer = (props) => {
  // collect highight events
  const [highights, setHighlights] = useState([]);

  useEffect(() => {
    if (!props.events) {
      return;
    }
    // get all highlights from events
    const highlights = props.events.filter((event) => event.highlight);
    // check if we have at least 1 highlight
    if (highlights.length === 0) {
      // just take the next 2 events
      setHighlights(props.events.slice(0, 4));
      return;
    }
    // sort by date
    highlights.sort((a, b) => {
      return new Date(a.start_date) - new Date(b.start_date);
    });
    // set max 4 highlights
    setHighlights(highlights.slice(0, 4));
  }, [props.events]);

  return (
    <div className="mainContainer dark">
      <div className="contentContainer no_border right-text top-text">
        <div className="carouselContainer">
          <CustomCarousel imagePaths={imagePaths} interval="15000" />
        </div>
        {/* title */}
        <div className="textContainer">
          <SubHeadline
            title="Feiern im Manitu"
            level="1"
            position="center"
            leftSpace="1.2rem"
          />
          <p className="text">
            Willkommen im Live Club MANITU – einem Ort voller Livemusik,
            Erlebnisprogramme, Frohsinn und Ausgelassenheit! Hier wird getanzt,
            gequatscht, gefeiert, gechillt und vor allem gelacht. Egal, ob
            Festivals, Livekonzerte, Discoabende, mittelalterliche
            Ritterbankette, musikalische Clubabende oder Whiskey Verkostungen –
            bei uns findet jeder das passende Event. Wir laden dich ein, Teil
            dieser einzigartigen Atmosphäre zu sein und unvergessliche Nächte
            voller Musik, Tanz und guter Laune zu erleben.
          </p>
          {/* <p className="text">
            Hier darf jeder sein wie er möchte, hier sind wir alle eine große
            Familie.
          </p> */}
          <p className="text">
            Auf Freundschaft. Auf Erinnerungen. Auf Abenteuer. Auf Partynächte.
            Auf Lachanfälle. AUF UNS!!!
          </p>
          <br />
          {props.events && (
            <div className="eventHighlight">
              <SubHeadline
                title="Kommende Highlights im Manitu:"
                level="3"
                position="left"
                leftSpace="0.8rem"
                color="var(--text-color)"
              />
              {/* if event exists */}
              <Row className="highlightRow">
                {highights.map((event) => (
                  <ListItem event={event} key={event.title} />
                ))}
              </Row>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstContainer;
