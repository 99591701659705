import React from "react";

// components
import { SubHeadline } from "../../components/Headlines";

// css
import styles from "./Styles.module.css";

const Impressum = () => {
  return (
    <div className={styles.mainContainer}>
      <SubHeadline
        position="center"
        border={true}
        title="Impressum"
        level="1"
      />
      <div className={styles.contentContainer}>
        <SubHeadline position="left" title="Angaben gemäß § 5 TMG" level="2" />
        <div className={styles.p_section}>
          <p>FRANJA Gastro Unternehmergesellschaft (haftungsbeschränkt)</p>
          <p>Frankfurter Straße 135</p>
          <p>03149 Forst (Lausitz)</p>
        </div>
        <div className={styles.p_section}>
          <p>Handelsregister: HRB 12829</p>
          <p>Registergericht: Amtsgericht Cottbus</p>
          <p>USt.ID: DE202303958 </p>
        </div>
        <div className={styles.p_section}>
          <h5>Vertreten durch:</h5>
          <p>Frank Pfitzmann</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline position="left" title="Kontakt" level="2" />
        <div className={styles.p_section}>
          <p>Telefon: 03562-665274</p>
          <p>Fax: 03562-693563</p>
          <p>
            E-Mail:
            <a href="mailto:info@manitu-forst.de" className={styles.email_link}>
              info@manitu-forst.de
            </a>
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline
          position="left"
          title="Redaktionell Verantwortlich"
          level="2"
        />
        <div className={styles.p_section}>
          <p>Jana Pfitzmann</p>
          <p>Frankfurter Straße 135</p>
          <p>03149 Forst (Lausitz)</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline position="left" title="EU-Streitschlichtung" level="2" />
        <div className={styles.p_section}>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
          </p>
          <p>
            <a
              className={styles.email_link}
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline
          position="left"
          title="Verbraucherstreitbeteiligung / Universal­schlichtungs­stelle"
          level="2"
        />
        <div className={styles.p_section}>
          <p className={styles.pText}>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline position="left" title="Haftung für Inhalte" level="2" />
        <div className={styles.p_section}>
          <p className={styles.pText}>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p className={styles.pText}>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <SubHeadline position="left" title="Haftung für Links" level="2" />
        <div className={styles.p_section}>
          <p className={styles.pText}>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p className={styles.pText}>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
