import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import emailjs from "emailjs-com";

// components
import { NotificationManager } from "react-notifications";

// css
import styles from "./ContactFormular.module.css";

const ContactFormular = (props) => {
  const createNotification = (type) => {
    return () => {
      switch (type) {
        case "success":
          NotificationManager.success(
            "Ihre Nachricht wurde erfolgreich versendet",
            "Erfolgreich",
            3000
          );
          break;
        case "error":
          NotificationManager.error(
            "Ihre Nachricht konnte nicht versendet werden. Probieren Sie es später noch einmal.",
            "Fehler",
            3000
          );
          break;
        default:
          break;
      }
    };
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          // check result status code
          if (result.status !== 200) {
            createNotification("error")();
            return;
          }
          createNotification("success")();
          e.target.reset();
        },
        (error) => {
          createNotification("error")();
          e.target.reset();
        }
      );
  };

  return (
    <Form onSubmit={sendEmail}>
      <Row>
        <Col sm={6} xs={12}>
          <FormGroup>
            <Label for="from_email">Email*</Label>
            <Input
              type="email"
              name="from_email"
              id="from_email"
              placeholder=""
              required
              className={styles.input}
              title="Ihre Email unter der wir Sie erreichen können"
            />
          </FormGroup>
        </Col>
        <Col sm={6} xs={12}>
          <FormGroup>
            <Label for="from_name">Name*</Label>
            <Input
              type="text"
              name="from_name"
              id="from_name"
              placeholder=""
              required
              className={styles.input}
              title="Ihr Name"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <FormGroup>
            <Label for="message">Nachricht*</Label>
            <Input
              type="textarea"
              rows="5"
              name="message"
              id="message"
              placeholder="Ihre Nachricht"
              required
              defaultValue={props.message}
              className={`form-control ${styles.input}`}
              title="Ihre Nachricht"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {/* check gdpr */}
        <Col md={12} xs={12}>
          <FormGroup>
            <Label>
              {/* color of checkbox = yellow */}
              <Input
                type="checkbox"
                name="gdpr"
                id="gdpr"
                required
                className={styles.checkbox}
              />{" "}
              *Ich habe die Datenschutzerklärung gelesen und ich stimme zu, dass
              meine Angaben zur Kontaktaufnahme und für Rückfragen dauerhaft
              gespeichert werden.
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <div className="arrowButtonContainer">
        <button
          className="arrowButton firstStage"
          type="submit"
          style={{ "--arrow-width": "15rem" }}
        >
          <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
          </span>
          <span className="arrowButton-text">Nachricht senden</span>
        </button>
      </div>
    </Form>
  );
};

export default ContactFormular;
