import React, { useEffect, useState } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

import { initGA } from "./ga_utils";

// css
import styles from "./CookieConsentComponent.module.css";
import "./consent_styling.css";

export default function CookieConsentComponent() {
  const [cookieStatus, setCookieStatus] = useState(getCookieConsentValue());
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAccept();
    }
  }, []);

  const handleAccept = () => {
    if (process.env.REACT_APP_GA_MESS_ID) {
      initGA(process.env.REACT_APP_GA_MESS_ID);
    }
    setCookieStatus("true");
  };
  const handleDecline = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    setCookieStatus("false");
  };

  return (
    // check if cookie is set
    // if not, show cookie consent
    // if yes, do nothing
    <>
      {cookieStatus ? null : (
        <div className={`${styles.cookieContainer}`}>
          <CookieConsent
            location="bottom"
            buttonText="Zustimmen"
            declineButtonText="Ablehnen"
            enableDeclineButton
            onAccept={handleAccept}
            onDecline={handleDecline}
            // styling
            disableStyles
            // overlayStyle={{ position: "relative" }}
            // contentStyle={{}}
          >
            Wir und ausgewählte Dritte setzen Cookies oder ähnliche Technologien
            für technische Zwecke ein und – mit Ihrer Einwilligung – für
            Funktionalität, Nutzererfahrung, Erfolgsmessung und Marketing. Im
            Fall der Ablehnung könnten bestimmte Funktionen nicht verfügbar
            sein. Ihre Einwilligung können Sie jederzeit erteilen, verweigern
            oder widerrufen. Verwenden Sie den „Zustimmen“-Button, um dem
            Einsatz solcher Technologien zuzustimmen. Verwenden Sie den
            „Ablehnen“-Button, um fortzufahren ohne zuzustimmen.
          </CookieConsent>
        </div>
      )}
    </>
  );
}
