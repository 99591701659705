import React from "react";

export const Headline = (props) => {
  const style = {
    textAlign: props.position,
    borderBottom: props.border && "#a35e00 3px solid",
    paddingBottom: props.bottomSpace ? props.bottomSpace : "0.5rem",
    marginBottom: props.bottomMargin ? props.bottomMargin : "0.5rem",
    paddingTop: props.topSpace ? props.topSpace : "0",
    paddingLeft: props.leftSpace ? props.leftSpace : "0",
    paddingRight: props.rightSpace ? props.rightSpace : "0",
    fontFamily: "Balthazar-Regular",
    color: props.color ? props.color : "rgb(255 153 0)",
    fontSize: props.fontSize && props.fontSize,
  };

  return (
    <div className="headline">
      <h1 style={style}>
        {props.title}
        {props.cancelled && (
          <span
            style={{
              fontSize: "1.3rem",
              fontWeight: "700",
              color: "#d01616",
              paddingLeft: "1rem",
            }}
          >
            Das Konzert wurde abgesagt
          </span>
        )}
      </h1>
    </div>
  );
};

export const SubHeadline = (props) => {
  const style = {
    textAlign: props.position,
    borderBottom: props.border && "#a35e00 3px solid",
    paddingBottom: props.bottomSpace ? props.bottomSpace : "0.5rem",
    marginBottom: props.bottomMargin ? props.bottomMargin : "0.5rem",
    paddingTop: props.topSpace ? props.topSpace : "0",
    paddingLeft: props.leftSpace ? props.leftSpace : "0",
    paddingRight: props.rightSpace ? props.rightSpace : "0",
    fontFamily: "Balthazar-Regular",
    color: props.color ? props.color : "rgb(255 153 0)",
    fontSize: props.fontSize && props.fontSize,
  };

  return (
    <div className="subHeadline">
      {props.level === "4" && <h4 style={style}>{props.title}</h4>}
      {props.level === "2" && <h2 style={style}>{props.title}</h2>}
      {props.level === "1" && <h1 style={style}>{props.title}</h1>}
      {props.level === "3" && <h3 style={style}>{props.title}</h3>}
    </div>
  );
};
