import React from "react";
import { NavItem, NavLink } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";

// css
import styles from "./Header.module.css";

const NavigationItem = ({ link, text }) => {
  return (
    <NavItem className={`${styles.navItem}`}>
      <RRNavLink
        to={link}
        className={({ isActive }) =>
          [styles.navLink, isActive ? styles.active : null]
            .filter(Boolean)
            .join(" ")
        }
      >
        {text}
      </RRNavLink>
    </NavItem>
  );
};

export default NavigationItem;
