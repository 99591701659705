import React from "react";

// css
import styles from "./About.module.css";

// components
import AboutImage from "./AboutImage";
import { SubHeadline } from "../../components/Headlines";

const About = () => {
  return (
    <div className={`${styles.aboutMainContainer}`}>
      <div className={`${styles.aboutPart}`}>
        {/* <div className={styles.aboutContent}> */}
        <div className="contentContainer">
          <SubHeadline position="center" title="Die Anfänge" level="1" />
          <p className={styles.aboutText}>
            Am Anfang stand der Hinweis, dass da am Rande von Forst ein alter
            Sportplatz mit Turnhalle, Vereinsräumen und Wohnung zum Verkauf
            steht. Allerdings stand dieses Objekt seit mehreren Jahren leer, von
            Mal zu Mal wurde mehr zerstört und verfiel zusehends. Kein Dach war
            mehr zu retten, die Dielung im Saal war zum großen Teil zerstört und
            rausgesägt, die Fenster waren nicht mehr nutzbar und selbst manche
            Wände mussten abgetragen und neu aufgebaut werden. Natürlich war
            auch kein einziges Kabel mehr nutzbar. Drei Jahre lang waren wir die
            einzigen Interessenten für dieses Objekt und als es dann restlos
            kaputt war, bekamen wir den Zuschlag für diese Ruine. Ein trostloser
            Anblick!
          </p>
        </div>
        <AboutImage
          image={"/static-images/about/anfang/1.jpg"}
          description={
            "Ein trostloser Anblick. Keiner wollte diese Ruine aber wir sahen das Potential."
          }
        />
        <AboutImage
          image={"/static-images/about/anfang/2.jpg"}
          description={"Am Anfang war entkernen und entrümpeln angesagt."}
        />
        <AboutImage
          image={"/static-images/about/anfang/3.jpg"}
          description={
            "So sah es in allen Räumen aus. Kein Wunder, dass dieses Objekt niemand haben wollte."
          }
        />
        <AboutImage
          image={"/static-images/about/anfang/4.jpg"}
          description={"Selbst das Dach war kaum noch vorhanden."}
        />
      </div>
      <div className={`${styles.aboutPart} dark`}>
        {/* <div className={styles.aboutContent}> */}
        <div className="contentContainer">
          <SubHeadline position="center" title="Der Umbau beginnt" level="1" />
          <p className={styles.aboutText}>
            Jung und voller Tatendrang machten wir uns ans Werk und bauten
            zunächst die Wohnung aus, damit wir nicht jeden Tag nach der Arbeit
            erst zum „alten Sportplatz“ fahren mussten. So konnten wir dann
            jeden Tag wenigstens eine Stunde länger an unserem Traum arbeiten.
            Und bald konnte man auch die ersten Fortschritte sehen.
          </p>
          <p className={styles.aboutText}>
            Und wer arbeitet soll auch feiern. So fanden auch damals schon die
            ersten Partys statt. Alles zwar auf einer „Baustelle“ aber das tat
            der Stimmung keinen Abbruch. Die erste große Party war dann auch die
            eigene Hochzeit und sogar die Zeitungen in Forst berichteten von
            dieser außergewöhnlichen „Biker-Hochzeit“! Die Skeptiker waren auch
            nicht weit und prophezeiten uns höchstens ein Jahr. Hier am Rande
            von Forst kann das nichts werden und außerdem sieht das mit dem
            vielen Holz aus wie ein Eselstall! Das hielt uns aber nicht davon
            ab, alles so durchzuziehen, wie wir es uns vorstellten.
            Professionelle Anbieter kamen uns nicht ins Haus und selbst Tische,
            Regale oder Treppen wurden selbst gebaut.
          </p>
        </div>
        <AboutImage
          image={"/static-images/about/bau/1.jpg"}
          description={"10 Grad Minus aber der Bau musste weitergehen"}
        />
        <AboutImage
          image={"/static-images/about/bau/2.jpg"}
          description={
            "Das Dach musste weg und auch gleich noch die Grundmauern"
          }
        />
        <AboutImage
          image={"/static-images/about/bau/3.jpg"}
          description={
            "40 meter Abwasserkanal musste gegraben werden - zum Glück halfen viele Freunde"
          }
        />
        <AboutImage
          image={"/static-images/about/bau/4.jpg"}
          description={
            "Vom Eingangsbereich des Gastraumes blieb nicht mehr viel übrig. Eigentlich ein kompletter Neubau."
          }
        />
        <AboutImage
          image={"/static-images/about/bau/5.jpg"}
          description={
            "Der Innenausbau konnte beginnen aber für den Nachwuchs war auch noch Zeit."
          }
        />
        <AboutImage
          image={"/static-images/about/bau/6.jpg"}
          description={
            "Das Geschenk zur Hochzeit - ein Rasentraktor. Er fährt heute noch immer."
          }
        />
        <AboutImage
          image={"/static-images/about/bau/7.jpg"}
          description={
            "Die erste Party im provisorisch hergerichteten Saal. Die geilste Party aller Zeiten"
          }
        />
      </div>
      <div className={`${styles.aboutPart}`}>
        {/* <div className={styles.aboutContent}> */}
        <div className="contentContainer">
          <SubHeadline position="center" title="Die ersten Partys" level="1" />
          <p className={styles.aboutText}>
            1999 konnten wir mit einem Rockfestival offiziell den Club eröffnen
            und von da an gab es regelmäßig Livekonzerte und auch die ersten
            „anderen Veranstaltungen“. Die Konzerte fanden größtenteils auf
            einer provisorischen Bühne in der notdürftig hergerichteten
            Turnhalle statt. Und dann hieß es – Kontakte knüpfen. Das MANITU war
            komplett neu in der Szene und wir mussten uns erst einen Namen
            machen. Aber Zuverlässigkeit und das familiäre Ambiente sorgten
            schnell für einen guten Ruf und so konnten wir bald schon die ersten
            großen Agenturen für uns gewinnen. Mit der Zeit meldeten sich die
            Bands selbst und wollten im MANITU spielen. Es kamen dann auch die
            ersten Anfragen für private Familienfeiern. Das war zwar so nicht
            geplant aber nach den ersten Privatfeiern sprach es sich herum und
            wurde bis heute ein Erfolg.
          </p>
        </div>
        <AboutImage
          image={"/static-images/about/eroeffnung/3.jpg"}
          description={""}
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/1.jpg"}
          description={"Die ersten provisorischen Bars und Theken"}
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/2.jpg"}
          description={
            "Die Band DADDY LONG LEGS wollten beim ersten mal 85 Leute sehen. Zwei Jahre später waren ihre Konzerte ausverkauft."
          }
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/4.jpg"}
          description={"Die Campari-Mädels waren ein echter Blickfang"}
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/5.jpg"}
          description={
            "Die Band DOUBLE X war grandios - und brachte beinahe die Bühne zum Einsturz."
          }
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/6.jpg"}
          description={
            "Die typischen Bikerspiele - Balkenschieben statt Seilziehen"
          }
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/7.jpg"}
          description={"Gemeinsame Ausfahrt bei den ersten Bikertreffen"}
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/8.jpg"}
          description={
            "Das erste Ritteresssen übertraf alle Erwartungen und ist bis heute ein Highlight im Veranstaltungskalender"
          }
        />
        <AboutImage
          image={"/static-images/about/eroeffnung/9.jpg"}
          description={
            "Schon vor der Eröffnung fanden heftige Partys mit Freunden statt"
          }
        />
      </div>
      <div className={`${styles.aboutPart} dark`}>
        {/* <div className={styles.aboutContent}> */}
        <div className="contentContainer">
          <SubHeadline position="center" title="Der neue Saal" level="1" />
          <p className={styles.aboutText}>
            2002 war dann die Zeit gekommen, den alten Saal stilgerecht
            umzubauen. Dazu saßen wir für ein paar Tage im alten Saal und
            zeichneten, nahmen Maß, verwarfen wieder und kamen unseren
            Vorstellungen immer näher.
          </p>
          <p className={styles.aboutText}>
            Nach einer Woche war die Planung beendet und die Bestellung im
            Sägewerk war sehr umfangreich. Jeder Balken und jedes Brett ging
            dann durch unsere Hände und so entstand ein ganz eigener und
            individueller Veranstaltungssaal.
          </p>
          <p className={styles.aboutText}>
            Natürlich ist die Gestaltung nie abgeschlossen und ständig wird
            etwas verändert oder kommt Neues hinzu. Oft bringen Gäste etwas zur
            Dekoration mit oder möchten einfach mal ihren Gästen das MANTIU und
            den Saal zeigen.
          </p>
        </div>
        <AboutImage
          image={"/static-images/about/umbau/1.jpg"}
          description={
            "Die erste provisorische Bühne und das selbstgemalte Manitu-Logo"
          }
        />
        <AboutImage
          image={"/static-images/about/umbau/2.jpg"}
          description={
            "Das Holz für den neuen Saal - der Chef vom Sägewerk freute sich"
          }
        />
        <AboutImage
          image={"/static-images/about/umbau/3.jpg"}
          description={
            "Die neue, gebrauchte Theke steht schon im Saal und die ersten Gäste stehen bereit"
          }
        />
        <AboutImage
          image={"/static-images/about/umbau/4.jpg"}
          description={"Die ganze Familie packte mit an"}
        />
        <AboutImage
          image={"/static-images/about/umbau/5.jpg"}
          description={
            "So langsam nahm der neue Saal Gestalt an - aber nur der Chef wusste, wie es am Ende aussehen sollte"
          }
        />
        <AboutImage
          image={"/static-images/about/umbau/7.jpg"}
          description={"Der Prüfstatiker lachte nur und winkte beruhigt ab"}
        />
        <AboutImage
          image={"/static-images/about/umbau/6.jpg"}
          description={"So sah dann das Ergebnis aus"}
        />
        <AboutImage
          image={"/static-images/about/umbau/8.jpg"}
          description={
            "Genau so hatte es der Chef vor Beginn der Arbeiten schon im Kopf"
          }
        />
      </div>

      <div className={`${styles.aboutPart}`}>
        {/* <div className={styles.aboutContent}> */}
        <div className="contentContainer">
          <SubHeadline
            position="center"
            title="Livemusik seit fast 25 Jahren"
            level="1"
          />
          <p className={styles.aboutText}>
            Obwohl man uns höchstens ein Jahr gegeben hat, sind es nun fast 25
            Jahre geworden. In der Musikszene konnten wir uns längst auch
            international einen guten Ruf erwerben und unsere Gäste fahren nicht
            selten mehrere hundert Kilometer, um an unseren Konzerten
            teilzunehmen. Einige Feste haben sich inzwischen zu einer festen
            Größe etabliert. So zum Beispiel das große Rockfestival „Wigwam
            Getöse“, das immer am letzten Wochenende im Juli stattfindet und der
            musikalische Höhepunkt der Jahres ist. Inzwischen spielen dort acht
            Bands auf drei Bühnen. Lange schon kein Geheimtipp mehr ist auch die
            „Bluesmesse“. Namhafte Bluesbands und viele Gäste aus der Szene
            kommen dann hier zusammen und zelebrieren gemeinsam eine
            „Bluesmesse“! Das ist dann immer wie ein großes Familienfest.
            Vereint durch die Musik!
          </p>
        </div>
        <AboutImage
          image={"/static-images/about/heute/1.jpg"}
          description={"Die Rubettes sorgten für eine gigantische Party"}
        />
        <AboutImage
          image={"/static-images/about/heute/2.jpg"}
          description={"Erja Lyytinen bei ihrem Auftritt im Manitu"}
        />
        <AboutImage
          image={"/static-images/about/heute/3.jpg"}
          description={
            "Das Wigam Getöse ist jedes Jahr der musikalische Höhepunkt"
          }
        />
      </div>
    </div>
  );
};

export default About;
