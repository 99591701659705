import axios from "axios";

export const getBands = async (pageNumber = null) => {
  let url = `/api/bands/`;
  if (pageNumber) {
    url = `${url}?page=${pageNumber}`;
  }
  return await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
