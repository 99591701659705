import React from "react";

// css
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={`${styles.footer}`}>
      <span className={styles.span}>@2022</span> - Manitu. All rights reserved.
      <br />
      <a className={styles.link} href="/impressum">
        Impressum
      </a>{" "}
      |{" "}
      <a className={styles.link} href="/datenschutz">
        Datenschutz
      </a>
    </footer>
  );
};

export default Footer;
