import React from "react";

// components
import { SubHeadline } from "../../components/Headlines";

// css
import styles from "./Styles.module.css";

const Datenschutz = () => {
  return (
    <div className={styles.mainContainer}>
      <SubHeadline
        position="center"
        border={true}
        title="Datenschutzerklärung"
        level="1"
      />
      <div className={styles.contentContainer}>
        <SubHeadline
          position="left"
          title="1. Datenschutz auf einen Blick"
          level="2"
        />
        <SubHeadline position="left" title="Allgemeine Hinweise" level="4" />
        <div className={styles.p_section}>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </div>
        <SubHeadline
          position="left"
          title="Datenerfassung auf dieser Website"
          level="4"
        />
        <div className={styles.p_section}>
          <h5>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </h5>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <h5>Wie erfassen wir Ihre Daten?</h5>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>
          <h5>Wofür nutzen wir Ihre Daten?</h5>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit unter der im Impressum angegebenen Adresse an uns
            wenden.
          </p>
        </div>

        <SubHeadline
          position="left"
          title="Analyse-Tools und Tools von Drittanbietern"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.
          </p>
          <p>
            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
            der folgenden Datenschutzerklärung.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="2. Hosting und Content Delivery Networks (CDN)"
          level="2"
        />
        <div className={styles.p_section}>
          <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
        </div>

        <SubHeadline position="left" title="Strato" level="4" />
        <div className={styles.p_section}>
          <p>
            Anbieter ist die Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin
            (nachfolgend „Strato“). Wenn Sie unsere Website besuchen, erfasst
            Strato verschiedene Logfiles inklusive Ihrer IP-Adressen.
          </p>
          <p>
            Weitere Informationen entnehmen Sie der Datenschutzerklärung von
            Strato:{" "}
            <a href="https://www.strato.de/datenschutz/" className={styles.a}>
              https://www.strato.de/datenschutz/
            </a>
            .
          </p>
          <p>
            Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <h5>Abschluss eines Vertrages über Auftragsverarbeitung</h5>
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
            genannten Anbieter geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
            dass dieser die personenbezogenen Daten unserer Websitebesucher nur
            nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
        </div>
        <SubHeadline position="left" title="Cloudflare" level="4" />
        <div className={styles.p_section}>
          <p>
            Wir nutzen den Service „Cloudflare“. Anbieter ist die Cloudflare
            Inc., 101 Townsend St., San Francisco, CA 94107, USA (im Folgenden
            „Cloudflare”).
          </p>
          <p>
            Cloudflare bietet ein weltweit verteiltes Content Delivery Network
            mit DNS an. Dabei wird technisch der Informationstransfer zwischen
            Ihrem Browser und unserer Website über das Netzwerk von Cloudflare
            geleitet. Das versetzt Cloudflare in die Lage, den Datenverkehr
            zwischen Ihrem Browser und unserer Website zu analysieren und als
            Filter zwischen unseren Servern und potenziell bösartigem
            Datenverkehr aus dem Internet zu dienen. Hierbei kann Cloudflare
            auch Cookies oder sonstige Technologien zur Wiedererkennung von
            Internetnutzern einsetzen, die jedoch allein zum hier beschriebenen
            Zweck verwendet werden.
          </p>
          <p>
            Der Einsatz von Cloudflare beruht auf unserem berechtigten Interesse
            an einer möglichst fehlerfreien und sicheren Bereitstellung unseres
            Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
          </p>

          <p>
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:{" "}
            <a
              href="https://www.cloudflare.com/privacypolicy/"
              className={styles.a}
            >
              https://www.cloudflare.com/privacypolicy/
            </a>
            .
          </p>
          <p>
            Weitere Informationen zum Thema Sicherheit und Datenschutz bei
            Cloudflare finden Sie hier:{" "}
            <a
              href="https://www.cloudflare.com/privacypolicy/"
              className={styles.a}
            >
              https://www.cloudflare.com/privacypolicy/
            </a>
            .
          </p>
        </div>
        <SubHeadline
          position="left"
          title="3. Allgemeine Hinweise und Pflichtinformationen"
          level="2"
        />
        <SubHeadline position="left" title="Datenschutz" level="4" />
        <div className={styles.p_section}>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>

          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>

          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Hinweis zur verantwortlichen Stelle"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>FRANJA Gastro Unternehmergesellschaft (haftungsbeschränkt)</p>
          <p>Frankfurter Straße 135</p>
          <p>03149 Forst</p>
          <br />
          <p>Telefon: 03562-665274</p>
          <p>
            E-Mail:{" "}
            <a href="mailto:info@manitu-forst.de" className={styles.email_link}>
              info@manitu-forst.de
            </a>
          </p>
          <br />
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
        </div>
        <SubHeadline position="left" title="Speicherdauer" level="4" />
        <div className={styles.p_section}>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
            Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Widerruf Ihrer Einwilligung zur Datenverarbeitung"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Beschwerderecht bei der zuständigen Aufsichtsbehörde"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Recht auf Daten­übertrag­barkeit"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Auskunft, Löschung und Berichtigung"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="Recht auf Einschränkung der Verarbeitung"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </p>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="SSL- bzw. TLS-Verschlüsselung"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="4. Datenerfassung auf dieser Website"
          level="2"
        />
        <SubHeadline position="left" title="Cookies" level="4" />
        <div className={styles.p_section}>
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt.
          </p>
          <p>
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem
            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
            bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
            technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
            funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
            von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
            auszuwerten oder Werbung anzuzeigen.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
            Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
            erforderlich sind (notwendige Cookies), werden auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Speicherung von notwendigen Cookies
            zur technisch fehlerfreien und optimierten Bereitstellung seiner
            Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
            vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt
            die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </p>
        </div>
        <SubHeadline position="left" title="Kontaktformular" level="4" />
        <div className={styles.p_section}>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>
        </div>

        <SubHeadline
          position="left"
          title="Anfrage per E-Mail, Telefon oder Telefax"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </p>
        </div>
        <SubHeadline
          position="left"
          title="5. Analyse-Tools und Werbung"
          level="2"
        />
        <SubHeadline position="left" title="Google Analytics" level="4" />
        <div className={styles.p_section}>
          <p>
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Ireland Limited („Google“),
            Gordon House, Barrow Street, Dublin 4, Irland.
          </p>
          <p>
            Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten
            der Websitebesucher zu analysieren. Hierbei erhält der
            Websitebetreiber verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe,
            Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers.
            Diese Daten werden von Google ggf. in einem Profil zusammengefasst,
            das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist.
          </p>
          <p>
            Google Analytics verwendet Technologien, die die Wiedererkennung des
            Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen
            (z.B. Cookies oder Device-Fingerprinting). Die von Google erfassten
            Informationen über die Benutzung dieser Website werden in der Regel
            an einen Server von Google in den USA übertragen und dort
            gespeichert.
          </p>
          <p>
            Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
            Webangebot als auch seine Werbung zu optimieren. Sofern eine
            entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
            zur Speicherung von Cookies), erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:{" "}
            <a
              href="https://privacy.google.com/businesses/controllerterms/mccs/"
              className={styles.a}
            >
              https://privacy.google.com/businesses/controllerterms/mccs/
            </a>
            .
          </p>
          <h5>IP Anonymisierung</h5>
          <p>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung
            aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
            Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
            Daten von Google zusammengeführt.
          </p>
          <h5>Browser Plugin</h5>
          <p>
            Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
            verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              className={styles.a}
            >
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            . Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
            finden Sie in der Datenschutzerklärung von Google:{" "}
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              className={styles.a}
            >
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
            .
          </p>
          <h5>Auftragsverarbeitung</h5>
          <p>
            Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
            abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
            um.
          </p>
          <h5>Speicherdauer</h5>
          <p>
            Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
            Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
            DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach
            14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie
            unter folgendem Link:{" "}
            <a
              href=" https://support.google.com/analytics/answer/7667196?hl=de"
              className={styles.a}
            >
              https://support.google.com/analytics/answer/7667196?hl=de
            </a>
            .
          </p>
        </div>
        <SubHeadline position="left" title="7. Plugins und Tools" level="2" />
        <SubHeadline
          position="left"
          title="YouTube mit erweitertem Datenschutz"
          level="4"
        />
        <div className={styles.p_section}>
          <p>
            Diese Website bindet Videos der YouTube ein. Betreiber der Seiten
            ist die Google Ireland Limited („Google“), Gordon House, Barrow
            Street, Dublin 4, Irland.
          </p>

          <p>
            Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
            bewirkt laut YouTube, dass YouTube keine Informationen über die
            Besucher auf dieser Website speichert, bevor diese sich das Video
            ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
            erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
            So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen
            – eine Verbindung zum Google DoubleClick-Netzwerk her.
          </p>

          <p>
            Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
            Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
            YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen.
          </p>

          <p>
            Des Weiteren kann YouTube nach Starten eines Videos verschiedene
            Cookies auf Ihrem Endgerät speichern oder vergleichbare
            Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen.
            Auf diese Weise kann YouTube Informationen über Besucher dieser
            Website erhalten. Diese Informationen werden u. a. verwendet, um
            Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
            verbessern und Betrugsversuchen vorzubeugen.
          </p>

          <p>
            Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
            Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
            Einfluss haben.
          </p>

          <p>
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen über Datenschutz bei YouTube finden Sie in
            deren Datenschutzerklärung unter:{" "}
            <a
              href="https://policies.google.com/privacy?hl=de"
              className={styles.a}
            >
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
        </div>
        <SubHeadline position="left" title="Google Web Fonts" level="4" />
        <div className={styles.p_section}>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Google Fonts, die von Google bereitgestellt werden. Beim
            Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in ihren
            Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          </p>

          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse diese Website aufgerufen wurde.
            Die Nutzung von Google Fonts erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der einheitlichen Darstellung des Schriftbildes auf seiner Website.
            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
            Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
            von Cookies oder den Zugriff auf Informationen im Endgerät des
            Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
            Die Einwilligung ist jederzeit widerrufbar.
          </p>

          <p>
            Wenn Ihr Browser Google Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>

          <p>
            Weitere Informationen zu Google Fonts finden Sie unter{" "}
            <a
              href="https://developers.google.com/fonts/faq"
              className={styles.a}
            >
              https://developers.google.com/fonts/faq
            </a>{" "}
            und in der Datenschutzerklärung von Google:{" "}
            <a
              href="https://policies.google.com/privacy?hl=de"
              className={styles.a}
            >
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
