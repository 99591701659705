import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import Spacer from "../../components/spacer/Spacer";

// css
import styles from "./Event.module.css";

// components
import ImageDateContainer from "../../components/singleEvent/ImageDateContainer";
import LanguageContainer from "../../components/container/languageContainer/LanguageContainer";
import { Headline, SubHeadline } from "../../components/Headlines";
import ContactFormular from "../../components/contactFormular/ContactFormular";

// api
import { getEvent } from "../../api_functions/events_api";

const Event = ({ match, location }) => {
  const [event, setEvent] = useState(null);
  const [youtubeId, setYoutubeId] = useState(null);
  const [youtubeParams, setYoutubeParams] = useState(null);
  const { event_url } = useParams();

  const [descriptionHeight, setDescriptionHeight] = useState(null);
  const description_ref = useRef(null);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    // get the height of the description
    if (description_ref.current) {
      // get height in vh and set it
      let vh =
        (100 * description_ref.current.clientHeight) / window.innerHeight;
      setDescriptionHeight(vh);
    }
  }, [event]);

  useEffect(() => {
    // get the event from the backend
    getEvent(event_url).then((res) => {
      if (res.length > 0) {
        setEvent(res[0]);
        // set youtube id
        // https://www.youtube.com/watch?v=PAgCkdS8yTY&t=545
        let video_id = res[0].artist_video.split("v=")[1];
        video_id = video_id.includes("&") ? video_id.split("&")[0] : video_id;
        res[0].artist_video && setYoutubeId(video_id);
        // set youtube params
        if (res[0].artist_video) {
          let video_url = res[0].artist_video;
          if (video_url.includes("?")) {
            video_url = video_url.split("?")[1];
            if (video_url.includes("t=")) {
              video_url = video_url.replace("t=", "start=");
            }
            setYoutubeParams(video_url);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Spacer height="8rem" />
      {/* <LanguageContainer /> */}
      <div className={styles.mainContainer}>
        {event ? (
          <>
            {/* title */}
            <div className={styles.titleContainer}>
              <Headline
                title={event.title}
                level="1"
                position="left"
                leftSpace="1.2rem"
                border="true"
                cancelled={event.status === "CAN"}
              />
            </div>
            {/* image and additional information */}
            <ImageDateContainer event={event} />

            {/* description */}
            <div
              className={`${styles.descriptionContainer} ${
                descriptionHeight > 59 &&
                !showDescription &&
                styles.smallDescriptionContainer
              }`}
              ref={description_ref}
            >
              {/* render html description */}
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: event.description,
                }}
              />
              {descriptionHeight > 59 && !showDescription && (
                <div className={styles.showMoreDiv}>
                  <Button
                    className={styles.showMoreButton}
                    onClick={() => setShowDescription(true)}
                  >
                    Ganze Beschreibung anzeigen
                  </Button>
                </div>
              )}
            </div>

            {/* optional: youtube video https://www.youtube.com/watch?v=wBHYLnH0VEY */}
            {youtubeId && (
              <div className={styles.videoContainer}>
                <LiteYouTubeEmbed
                  id={youtubeId} // Default none, id of the video or playlist
                  adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                  params={youtubeParams} // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
                  playlist={false} // Use  true when your ID be from a playlist
                  noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
                />
              </div>
            )}
            {/* contact formular if no ticketshop available */}
            {event.ticket_url === null && (
              <>
                <div id="Kontakt"></div>
                <SubHeadline
                  position="left"
                  title="Karten reservieren"
                  level="4"
                  topSpace="4rem"
                />
                <p>
                  Für diese Veranstaltung gibt es nicht die Möglichkeit Tickets
                  zu bestellen. Sie können aber gerne Karten auf Ihren Namen
                  reservieren lassen. Senden Sie uns dazu einfach eine E-Mail
                  mit der Anzahl der gewünschten Tickets und Ihrem Namen oder
                  rufen Sie uns an.
                </p>
                <ContactFormular
                  message={`Guten Tag,\n\nich würde gerne <<Anzahl der Tickets>> Tickets auf den Namen <<Ihr Name>> für die Veranstaltung "${event.title}" reservieren.`}
                />
              </>
            )}
          </>
        ) : (
          // loading
          <div className={styles.loading}>Loading...</div>
        )}
      </div>
    </>
  );
};

export default Event;
