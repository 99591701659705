import React from "react";

// css
import styles from "./PartiesPage.module.css";

// components
import CustomCarousel from "../../components/carousel/CustomCarousel";
import { ArrowButton } from "../../components/buttons/Buttons";
import Spacer from "../../components/spacer/Spacer";
import { SubHeadline } from "../../components/Headlines";

const concertImagePaths = [
  "/static-images/parties/concerts/1.jpg",
  "/static-images/parties/concerts/2.jpg",
  "/static-images/parties/concerts/3.jpg",
  "/static-images/parties/concerts/4.jpg",
  "/static-images/parties/concerts/5.jpg",
];
const privateImagePaths = [
  "/static-images/parties/private/1.jpg",
  "/static-images/parties/private/2.jpg",
  "/static-images/parties/private/3.jpg",
  "/static-images/parties/private/4.jpg",
  "/static-images/parties/private/5.jpg",
];
const ritterImagePaths = [
  "/static-images/parties/ritter/1.jpg",
  "/static-images/parties/ritter/2.jpg",
  "/static-images/parties/ritter/3.jpg",
  "/static-images/parties/ritter/4.jpg",
  "/static-images/parties/ritter/5.jpg",
];
const whiskeyImagePaths = [
  "/static-images/parties/whiskey/1.jpg",
  "/static-images/parties/whiskey/2.jpg",
  "/static-images/parties/whiskey/3.jpg",
];
const wigwamImagePaths = [
  "/static-images/parties/wigwam/heldmaschine.jpg",
  "/static-images/parties/wigwam/megaherz.jpg",
  "/static-images/parties/wigwam/fans.jpg",
  "/static-images/parties/wigwam/cornett.jpg",
  "/static-images/parties/wigwam/zelt.jpg",
  "/static-images/parties/wigwam/beleuchtung.jpg",
  "/static-images/parties/wigwam/brothers.jpg",
  "/static-images/parties/wigwam/gregor.jpg",
  "/static-images/parties/wigwam/jailbreakers.jpg",
];

const PartiesPage = () => {
  return (
    <div style={{ paddingTop: "6rem" }}>
      {/* Very First Container - concerts */}
      <div className="mainContainer light">
        <div className="contentContainer no_border left-text top-text">
          <div className={`carouselContainer ${styles.carouselContainer}`}>
            <CustomCarousel imagePaths={concertImagePaths} interval="18000" />
          </div>
          <div className="textContainer">
            <SubHeadline
              title="Konzerte und Festivals"
              level="1"
              position="center"
              leftSpace="1.2rem"
            />
            <p className="text">
              Der Schwerpunkt liegt im „Live Club MANITU“ – natürlich auf der
              Livemusik. Und weil sich der Chef des Hauses für (fast) jede
              Musikrichtung begeistern kann, ist die Vielfalt der Bands auch so
              abwechslungsreich.
            </p>
            <p className="text">
              Blues, Rock, Metal, Gothic, Folk, Mittelalterrock, NHD – es ist
              für fast jeden Fan etwas dabei. Viele unserer Acts sind
              mittlerweile international bekannt und bringen somit die große,
              weite Welt nach Forst ins MANITU.
            </p>
            <p className="text">
              Wir genießen gemeinsam mit der großen Familie der Livemusikfans
              das besondere Flair der Konzerte, feiern gemeinsam mit den Bands
              riesen Partys. Autogramme holen, gemeinsam mit den
              Lieblingsmusikern Fotos machen und nach dem Konzert mit ihnen noch
              an der Theke stehen.
            </p>
            <p className="text">
              Es gibt für uns im MANITU nichts Schöneres als die Livemusik!
            </p>
          </div>
        </div>
      </div>

      {/* First Container - private parties */}
      <div className="mainContainer dark">
        <div className="contentContainer no_border right-text top-text">
          <div className={`carouselContainer ${styles.carouselContainer}`}>
            <CustomCarousel imagePaths={privateImagePaths} interval="22000" />
          </div>
          <div className="textContainer">
            <SubHeadline
              title="Privat Feiern im Manitu"
              level="1"
              position="center"
              leftSpace="1.2rem"
            />
            <p className="text">
              Natürlich können Sie im MANITU auch Geburtstage, Betriebsfeiern
              oder Hochzeiten ausrichten lassen. Die rustikale Atmosphäre sorgt
              für eine gemütliche und ausgelassene Stimmung unter Ihren Gästen.
              Dazu steht Ihnen der Gastraum, der Saal oder der neue überdachte
              Biergarten zur Verfügung, oder auch einer Kombination aus allen
              Räumlichkeiten. Selbstverständlich stellen wir Ihnen auch ein
              Buffet zusammen, das ganz nach Ihren Wünschen gestaltet ist.
            </p>
            <p className="text">
              Dabei stehen wir Ihnen mit unserer jahrelangen Erfahrung zur Seite
              und begleiten Sie bei der Vorbereitung, so dass Ihre Feier zu
              einem unvergesslichen Erlebnis wird. Auch ausgefallene Wünsche
              sind bei uns kein Problem und Sie können bei der Wahl eines
              Kulturprogramms aus dem Vollen schöpfen. Ob Disco, Liveband,
              Kinderprogramm oder Erlebnisprogramm – nichts ist unmöglich.
            </p>
            <p className="text">
              Zögern Sie nicht, uns zu kontaktieren, um Ihre private Feier im
              MANITU zu planen.
            </p>
            <Spacer height="0.5rem" />
            <div className="buttonContainer">
              <ArrowButton
                value={"Kontakt aufnehmen"}
                url={"/contact#Formular"}
                width="16.5rem"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Second Container - Ritteressen */}
      <div className="mainContainer light">
        <div className="contentContainer no_border left-text top-text">
          <div className={`carouselContainer ${styles.carouselContainer}`}>
            <CustomCarousel imagePaths={ritterImagePaths} interval="20000" />
          </div>
          <div className="textContainer">
            <SubHeadline
              title="Ritteressen"
              level="1"
              position="center"
              leftSpace="1.2rem"
            />
            <p className="text">
              Von Anfang an ist das jährliche „Ritteressen“ ein großes Erlebnis!{" "}
              <b>
                Mal richtig die Sau rauslassen und alle Tischsitten vergessen!
              </b>{" "}
              Musik und Tanz mit holden Maiden, Sackpfeifenmusik mit
              Trommelschlag, Mystik und Feuerzauber, Geisteraustreiben und
              Hexenspuk, Jungbrunnen und ewige Jugend, Ritterlicher Empfang und
              Ritterschlag, Bösewichter und Unholde, Ritt auf der Kuhhaut,
              historische Bankette und Bänkelsang und allerhand mehr. So lasset
              Euch entführen in eine längst vergangene Zeit voller Romantik und
              Abenteuerlust.
            </p>
            <p className="text">
              Außerdem gibt es ein deftiges 7-Gang-Menü, wo garantiert jeder
              Gast satt wird. Es ist natürlich auch möglich, in privater Runde
              ein Ritteressen zu buchen. Egal ob Geburtstagsparty oder
              Weihnachtsfeier, ob groß oder klein, mit oder ohne
              Erlebnisprogramm – wir finden gemeinsam mit Ihnen den richtigen
              Rahmen. Selbstverständlich erhalten Sie bei uns auch hausgemachten
              Met – ganz nach Wunsch im Glas, im Krug, in der Kelle oder für den
              Weg abgefüllt in der Flasche.
            </p>
          </div>
        </div>
      </div>

      {/* Third Container - Whiskey Lounge */}
      <div className="mainContainer dark">
        <div className="contentContainer no_border right-text top-text">
          <div className={`carouselContainer ${styles.carouselContainer}`}>
            <CustomCarousel imagePaths={whiskeyImagePaths} interval="230000" />
          </div>
          <div className="textContainer">
            <SubHeadline
              title="Whiskey Lounge"
              level="1"
              position="center"
              leftSpace="1.2rem"
            />
            <p className="text">
              Sehr großer Beliebtheit erfreuen sich immer wieder die
              „Whisky-Lounge-Abende“!
            </p>
            <p className="text">
              Diese Abende sind keine Whisky-Verkostungen im herkömmlichen
              Sinne, sondern zwanglose „Clubabende“. Dort hat man die
              Gelegenheit in äußerst gemütlicher Runde bei Kerzenschein, einer
              guten Zigarre und akustischer Livemusik mit Freunden das Leben zu
              genießen. Man kann auch aus über 350 Sorten Whisky probieren und
              versuchen, seinen persönlichen Favoriten zu finden. Man muss auch
              keinen Whisky trinken. Auch ein guter Wein, Rum oder Cocktails
              können Ihren Abend begleiten. Für alle Whisky-Liebhaber haben wir
              eine umfangreiche Whiskykarte zur Verfügung, bei der alle
              erhältlichen Sorten mit einer Geschmacksbeschreibung versehen
              sind. So wird das private „Verkosten“ zu einem spannenden und
              informativen Erlebnis.
            </p>
            <p className="text">
              Der Gastraum wird dann ergänzt durch Teppiche und Sessel und durch
              die vielen Kerzen wird eine ausgesprochen gemütliche Atmosphäre
              erzeugt, die diesen Abend zu einem unvergesslichen Erlebnis macht.
            </p>{" "}
          </div>
        </div>
      </div>

      {/* Fourth Container - Wigwam */}
      <div className="mainContainer light">
        <div className="contentContainer no_border left-text top-text">
          <div className={`carouselContainer ${styles.carouselContainer}`}>
            <CustomCarousel imagePaths={wigwamImagePaths} interval="21000" />
          </div>
          <div className="textContainer">
            <SubHeadline
              title="Wigwam Getöse"
              level="1"
              position="center"
              leftSpace="1.2rem"
            />
            <p className="text">
              Seit 1999 gibt es am letzten Wochenende im Juli ein Rockfestival.
              Das <b>WIGWAM GETÖSE</b> entwickelt sich immer mehr zum
              Geheimtipp. Denn in der Szene spricht sich unser Festival immer
              mehr herum und auch namhafte Bands fragen inzwischen an, ob sie
              auf dem WIGWAM GETÖSE spielen dürfen. Natürlich gestalten wir auch
              das Rahmenprogramm dementsprechend umfangreich. So gibt es z.B.
              unterschiedliche Händler, Tattoo, ein großes Merchandising-Zelt,
              zum Chillen einen extra Bereich mit Cocktail-Bar und
              Shisha-Lounge. Der ganze Außenbereich wird natürlich wieder
              stimmungsvoll beleuchtet und viele Lagerfeuer laden zum Verweilen
              ein. Das ganze Programm findet auf drei Bühnen statt und Jeder
              kann sich aussuchen, welche Musik er gerade hören möchte.
              Regenwetter ist nicht schön, aber auch nicht schlimm, da die
              wichtigen Flächen überdacht sind. Am Start sind mindestens sieben
              Bands und man kann auf dem Gelände kostenlos zelten. Ganz
              besonders schätzen die Gäste die besondere familiäre und
              entspannte Atmosphäre dieses Festivals!
            </p>
          </div>
        </div>
      </div>

      {/* Fifth Container - Tattoo */}
      {/* <div className="mainContainer light">
        <div className="contentContainer no_border left-text bottom-text">
          <div className="carouselContainer">
            <CustomCarousel imagePaths={tattooImagePaths} />
          </div>
          <div className="textContainer">
            <h1 className="title">Wigwam Getöse</h1>
            <p className="text">
              Seit 1999 gibt es am letzten Wochenende im Juli ein Rockfestival.
              Das <b>WIGWAM GETÖSE</b> entwickelt sich immer mehr zum
              Geheimtipp. Denn in der Szene spricht sich unser Festival immer
              mehr herum und auch namhafte Bands fragen inzwischen an, ob sie
              auf dem WIGWAM GETÖSE spielen dürfen. Natürlich gestalten wir auch
              das Rahmenprogramm dementsprechend umfangreich. So gibt es z.B.
              unterschiedliche Händler, Tattoo, ein großes Merchandising-Zelt,
              zum Chillen einen extra Bereich mit Cocktail-Bar und
              Shisha-Lounge. Der ganze Außenbereich wird natürlich wieder
              stimmungsvoll beleuchtet und viele Lagerfeuer laden zum Verweilen
              ein. Das ganze Programm findet auf drei Bühnen statt und Jeder
              kann sich aussuchen, welche Musik er gerade hören möchte.
              Regenwetter ist nicht schön, aber auch nicht schlimm, da die
              wichtigen Flächen überdacht sind. Am Start sind mindestens sieben
              Bands und man kann auf dem Gelände kostenlos zelten. Ganz
              besonders schätzen die Gäste die besondere familiäre und
              entspannte Atmosphäre dieses Festivals!
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PartiesPage;
