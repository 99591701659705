export const guest_images = [
  {
    src: "/static-images/galerie/guests/1.JPG",
    thumbnail: "/static-images/galerie/guests/1_thumbnail.JPG",
    alt: "Bild 1",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/2.JPG",
    thumbnail: "/static-images/galerie/guests/2_thumbnail.JPG",
    alt: "Bild 2",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/3.JPG",
    thumbnail: "/static-images/galerie/guests/3_thumbnail.JPG",
    alt: "Bild 3",
    width: 1080,
    height: 1440,
  },
  {
    src: "/static-images/galerie/guests/4.JPG",
    thumbnail: "/static-images/galerie/guests/4_thumbnail.JPG",
    alt: "Bild 4",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/5.JPG",
    thumbnail: "/static-images/galerie/guests/5_thumbnail.JPG",
    alt: "Bild 5",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/6.JPG",
    thumbnail: "/static-images/galerie/guests/6_thumbnail.JPG",
    alt: "Bild 6",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/7.JPG",
    thumbnail: "/static-images/galerie/guests/7_thumbnail.JPG",
    alt: "Bild 7",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/17.JPG",
    thumbnail: "/static-images/galerie/guests/17_thumbnail.JPG",
    alt: "Bild 17",
    width: 1080,
    height: 1622,
  },
  {
    src: "/static-images/galerie/guests/8.JPG",
    thumbnail: "/static-images/galerie/guests/8_thumbnail.JPG",
    alt: "Bild 8",
    width: 1080,
    height: 1440,
  },
  {
    src: "/static-images/galerie/guests/9.JPG",
    thumbnail: "/static-images/galerie/guests/9_thumbnail.JPG",
    alt: "Bild 9",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/10.JPG",
    thumbnail: "/static-images/galerie/guests/10_thumbnail.JPG",
    alt: "Bild 10",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/11.JPG",
    thumbnail: "/static-images/galerie/guests/11_thumbnail.JPG",
    alt: "Bild 11",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/12.JPG",
    thumbnail: "/static-images/galerie/guests/12_thumbnail.JPG",
    alt: "Bild 12",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/13.JPG",
    thumbnail: "/static-images/galerie/guests/13_thumbnail.JPG",
    alt: "Bild 13",
    width: 1622,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/14.JPG",
    thumbnail: "/static-images/galerie/guests/14_thumbnail.JPG",
    alt: "Bild 14",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/15.JPG",
    thumbnail: "/static-images/galerie/guests/15_thumbnail.JPG",
    alt: "Bild 15",
    width: 1620,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/16.JPG",
    thumbnail: "/static-images/galerie/guests/16_thumbnail.JPG",
    alt: "Bild 16",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/guests/18.JPG",
    thumbnail: "/static-images/galerie/guests/18_thumbnail.JPG",
    alt: "Bild 18",
    width: 1619,
    height: 1080,
  },
];
