import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

// css
import "./Map.css";

const Map = (props) => {
  return (
    <div className={`mainMapContainer ${props.full && "full_map"}`}>
      <MapContainer
        center={[51.76493942473865, 14.636944866646296]}
        zoom={17}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[51.76493942473865, 14.636944866646296]}>
          <Popup>
            <u>
              <a
                href="https://goo.gl/maps/gszsBWpyBFqGq1Lu7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manitu
              </a>
            </u>
            <br />
            Frankfurter Str. 135
            <br />
            03149 Forst Lausitz
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
