import React from "react";
import emailjs from "emailjs-com";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

// css
import styles from "./Contact.module.css";

// components
import { ArrowButton } from "../../components/buttons/Buttons";
import Map from "../../components/map/Map";
import FAQSection from "./FAQSection";
import { SubHeadline } from "../../components/Headlines";
import ContactFormular from "../../components/contactFormular/ContactFormular";
import Spacer from "../../components/spacer/Spacer";

const Contact = () => {
  return (
    <>
      <div className="contentContainer">
        <Spacer height="6rem" />
        <div className="headerContainer "></div>
        <div>
          <Row>
            <Col md={6} xs={12}>
              <SubHeadline position="left" title="Kontakt" level="3" />
              <p className={styles.text}>
                Wenn Sie noch Fragen zu unserem Angebot haben oder Wünsche,
                zögern Sie nicht uns zu kontaktieren.
              </p>
              <h5 className={styles.text}>Adresse</h5>
              <p className={styles.text}>
                Frankfurter Straße 135,
                <br />
                03149 Forst (Lausitz)
              </p>
              <h5 className={styles.text}>Kontakt</h5>
              <p className={styles.text}>
                <b>Telefon:</b> +49 3562 665274
                <br />
                <b>Email:</b>{" "}
                <a className={styles.mail_a} href="mailto:info@manitu-forst.de">
                  info@manitu-forst.de
                </a>
              </p>
              {/* <h5 className={styles.text}>Öffnungszeiten</h5>
              <p className={styles.text}>
                <b>Montag - Freitag:</b> 8:00 - 17:00 Uhr
                <br />
                <b>Samstag:</b> 8:00 - 12:00 Uhr
              </p> */}
              <h5 className={styles.text}>Social Media</h5>
              <p className={styles.social_media}>
                <a href="https://www.facebook.com/people/Manitu/100063475542914/">
                  <img
                    className={styles.icon}
                    src="/static-images/facebook_logo.png"
                    alt="facebook"
                    width={30}
                    height={30}
                  />
                </a>
                <a href="https://www.instagram.com/manitu.liveclub/">
                  <img
                    className={styles.icon}
                    src="/static-images/instagram_logo.png"
                    alt="instagram"
                    width={30}
                    height={30}
                  />
                </a>
              </p>
            </Col>
            {/* map */}
            <Col md={6} xs={12}>
              <Map />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <br />
              <h4 className={styles.text}>Der Kulturverein Manitu e.V.</h4>
              <p className={styles.text}>
                Der „Kulturverein Manitu e.V.“ ist ein gemeinnütziger Verein,
                der sich vorrangig der Arbeit mit Kindern und Jugendlichen
                widmet sowie die Bildung und Erziehung zur Aufgabe gemacht hat.
              </p>
              <p className={styles.text}>
                Seit vielen Jahren sammelt der Verein Geld für die
                „Clownsprechstunde“ der Kinderkrebsstation des Cottbuser
                Karl-Thiem-Klinikum.
              </p>
              <p className={styles.text}>
                Der Verein dient der Förderung von Kunst, Kultur, Sozialarbeit
                und Jugendarbeit.
              </p>
              <p className={styles.text}>
                Außerdem widmet sich der Verein der Pflege der geschichtlichen
                Historie der Stadt Forst von ca. 1150 bis 1750 und deren
                Darstellung des Liedgutes, historischer Tänze und Musik. Der
                Verein besteht aus den Interessengemeinschaften: IG Johannes
                Gaudium und IG historische Stadtwache.
              </p>
              <p className={styles.text}>
                <b>Mitgliedsbeitrag:</b> 5 Euro monatlich
              </p>
              <p className={styles.text}>
                <b>Ansprechpartner:</b> Bernd Lehmann
              </p>
            </Col>
          </Row>
          <br />
          {/* email contact form */}
          <p className={styles.text}>
            Bei Fragen oder Anregungen schreiben Sie uns gerne eine Nachricht
            über das folgende Kontaktformular.
          </p>
        </div>
      </div>
      <div id="Formular"></div>
      <br />
      <div className="mainContainer dark">
        <div className="contentContainer">
          <SubHeadline position="left" title="Kontaktformular" level="3" />
          <ContactFormular />
        </div>
      </div>
      <div className="mainContainer">
        <div className={`contentContainer ${styles.faqContentContainer}`}>
          <SubHeadline position="left" title="Fragen und Antworten" level="3" />
          <FAQSection />
        </div>
      </div>
    </>
  );
};

export default Contact;
