import logo from "./logo.svg";
import "./App.css";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import HeaderSelector from "./components/header/HeaderSelector";
import Homepage from "./pages/homepage/Homepage";
import About from "./pages/about/About";
import Bands from "./pages/bands/Bands";
import Event from "./pages/event/Event";
import Events from "./pages/events/Events";
import Gallery from "./pages/gallery/Gallery";
import Footer from "./components/footer/Footer";
import Impressum from "./pages/datenschutz_impressum/Impressum";
import Datenschutz from "./pages/datenschutz_impressum/Datenschutz";
import ScrollToTop from "./components/ScrollToTop";
import Kontakt from "./pages/contact/Contact";
import PartiesPage from "./pages/parties/PartiesPage";
import { NotificationContainer } from "react-notifications";

import CookieConsentComponent from "./cookies/CookieConsentComponent";

import axios from "axios";
if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
  axios.defaults.baseURL = window.location.origin;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderSelector />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/bands" element={<Bands />} />
        <Route exact path="/events" element={<PartiesPage />} />
        <Route exact path="/program" element={<Events />} />
        <Route exact path="/event/:event_url" element={<Event />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/impressum" element={<Impressum />} />
        <Route exact path="/datenschutz" element={<Datenschutz />} />
        <Route exact path="/contact" element={<Kontakt />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <CookieConsentComponent />
      <Footer />
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
