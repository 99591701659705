import React, { useRef } from "react";
import { Masonry } from "react-plock";
import { Gallery as PhotoGallery, Item } from "react-photoswipe-gallery";
import "photoswipe/style.css";

import { guest_images } from "./guest_images";
import { verein_images } from "./verein_images";
import { room_images } from "./room_images";
import { SubHeadline } from "../../components/Headlines";

// css
import styles from "./Gallery.module.css";
// import "./Gallery.css";

// // images
const masonry_config = {
  columns: [3, 3, 4],
  gap: [5, 10, 10],
  media: [480, 768, 1024],
};

const Gallery = () => {
  return (
    <div className={styles.mainContainer}>
      {/* <div className={styles.introText}>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
        rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
      </div> */}
      <div className={styles.imageSection}>
        {/* title */}
        {/* <h1 className={styles.title}>Unsere Räume</h1> */}
        <SubHeadline
          title="Unsere Räume"
          level="1"
          position="center"
          leftSpace="0rem"
          border="true"
          bottomMargin="1rem"
          bottomSpace="0rem"
        />
        <PhotoGallery>
          <Masonry
            items={room_images}
            config={masonry_config}
            render={(image, idx) => (
              <Item
                original={image.src}
                thumbnail={image.thumbnail}
                width={image.width}
                height={image.height}
                alt={image.alt}
                key={idx}
              >
                {({ ref, open }) => (
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.thumbnail}
                    src={image.thumbnail}
                    ref={ref}
                    onClick={open}
                    alt={image.alt}
                  />
                )}
              </Item>
            )}
          />
        </PhotoGallery>
      </div>
      <div className={styles.imageSection}>
        {/* title */}
        <SubHeadline
          title="Die Gäste im Manitu"
          level="1"
          position="center"
          leftSpace="0rem"
          border="true"
          bottomMargin="1rem"
          bottomSpace="0rem"
        />
        <PhotoGallery>
          <Masonry
            items={guest_images}
            config={masonry_config}
            render={(image, idx) => (
              <Item
                original={image.src}
                thumbnail={image.thumbnail}
                width={image.width}
                height={image.height}
                alt={image.alt}
                key={idx}
              >
                {({ ref, open }) => (
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.thumbnail}
                    src={image.thumbnail}
                    ref={ref}
                    onClick={open}
                    alt={image.alt}
                  />
                )}
              </Item>
            )}
          />
        </PhotoGallery>
      </div>
      <div className={styles.imageSection}>
        {/* title */}
        {/* <h1 className={styles.title}>Die Gäste im Manitu</h1> */}
        <SubHeadline
          title="Der Verein und seine Freunde"
          level="1"
          position="center"
          leftSpace="0rem"
          border="true"
          bottomMargin="1rem"
          bottomSpace="0rem"
        />
        <PhotoGallery>
          <Masonry
            items={verein_images}
            config={masonry_config}
            render={(image, idx) => (
              <Item
                original={image.src}
                thumbnail={image.thumbnail}
                width={image.width}
                height={image.height}
                alt={image.alt}
                key={idx}
              >
                {({ ref, open }) => (
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.thumbnail}
                    src={image.thumbnail}
                    ref={ref}
                    onClick={open}
                    alt={image.alt}
                  />
                )}
              </Item>
            )}
          />
        </PhotoGallery>
      </div>
    </div>
  );
};

export default Gallery;
