export const verein_images = [
  {
    src: "/static-images/galerie/verein/1.JPG",
    thumbnail: "/static-images/galerie/verein/1_thumbnail.JPG",
    alt: "Bild 1",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/2.JPG",
    thumbnail: "/static-images/galerie/verein/2_thumbnail.JPG",
    alt: "Bild 2",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/3.JPG",
    thumbnail: "/static-images/galerie/verein/3_thumbnail.JPG",
    alt: "Bild 3",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/4.JPG",
    thumbnail: "/static-images/galerie/verein/4_thumbnail.JPG",
    alt: "Bild 4",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/5.JPG",
    thumbnail: "/static-images/galerie/verein/5_thumbnail.JPG",
    alt: "Bild 5",
    width: 640,
    height: 853,
  },
  {
    src: "/static-images/galerie/verein/6.JPG",
    thumbnail: "/static-images/galerie/verein/6_thumbnail.JPG",
    alt: "Bild 6",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/7.JPG",
    thumbnail: "/static-images/galerie/verein/7_thumbnail.JPG",
    alt: "Bild 7",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/8.JPG",
    thumbnail: "/static-images/galerie/verein/8_thumbnail.JPG",
    alt: "Bild 8",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/9.JPG",
    thumbnail: "/static-images/galerie/verein/9_thumbnail.JPG",
    alt: "Bild 9",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/10.JPG",
    thumbnail: "/static-images/galerie/verein/10_thumbnail.JPG",
    alt: "Bild 10",
    width: 1920,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/11.JPG",
    thumbnail: "/static-images/galerie/verein/11_thumbnail.JPG",
    alt: "Bild 11",
    width: 1440,
    height: 1080,
  },
  {
    src: "/static-images/galerie/verein/12.JPG",
    thumbnail: "/static-images/galerie/verein/12_thumbnail.JPG",
    alt: "Bild 12",
    width: 1920,
    height: 1080,
  },
];
