import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import HomepageHeader from "./HomepageHeader";

const HeaderSelector = () => {
  const pathname = useLocation().pathname;

  return <>{pathname === "/" ? <HomepageHeader /> : <Header />}</>;
};

export default HeaderSelector;
