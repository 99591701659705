import React, { useState, useEffect } from "react";

import EventItem from "../eventItem/EventItem";
import { ArrowButton } from "../../../buttons/Buttons";
import { SubHeadline } from "../../../Headlines";

// css
import styles from "./PlainList.module.css";

const PlainList = ({ events }) => {
  return (
    <div className={`${styles.main_container} contentContainer`}>
      {/* <div className={styles.title}>Bevorstehende Veranstaltungen</div> */}
      <SubHeadline
        title="Bevorstehende Veranstaltungen"
        level="1"
        position="center"
        leftSpace="1.2rem"
      />
      {events.map((event) => (
        <EventItem event={event} key={event.title + event.start_date} />
      ))}
      <div className={styles.buttonContainer}>
        <ArrowButton
          value="Alle Veranstaltungen"
          dark={true}
          url={"/program"}
          width={"18rem"}
        />
      </div>
    </div>
  );
};

export default PlainList;
