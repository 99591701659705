export const room_images = [
  {
    src: "/static-images/galerie/rooms/avo.JPG",
    thumbnail: "/static-images/galerie/rooms/avo_thumbnail.JPG",
    alt: "Avo",
    width: 1080,
    height: 1619,
  },
  {
    src: "/static-images/galerie/rooms/balkon.JPG",
    thumbnail: "/static-images/galerie/rooms/balkon_thumbnail.JPG",
    alt: "Balkon",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/eingang.JPG",
    thumbnail: "/static-images/galerie/rooms/eingang_thumbnail.JPG",
    alt: "Eingang",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/klavier.JPG",
    thumbnail: "/static-images/galerie/rooms/klavier_thumbnail.JPG",
    alt: "Klavier",
    width: 1080,
    height: 1619,
  },
  {
    src: "/static-images/galerie/rooms/gaststaette.JPG",
    thumbnail: "/static-images/galerie/rooms/gaststaette_thumbnail.JPG",
    alt: "Gaststätte",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/kabuff.JPG",
    thumbnail: "/static-images/galerie/rooms/kabuff_thumbnail.JPG",
    alt: "Kabuff",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/saal_von_treppe.JPG",
    thumbnail: "/static-images/galerie/rooms/saal_von_treppe_thumbnail.JPG",
    alt: "Saal von Treppe",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/whiskey.JPG",
    thumbnail: "/static-images/galerie/rooms/whiskey_thumbnail.JPG",
    alt: "Whiskey",
    width: 960,
    height: 640,
  },
  {
    src: "/static-images/galerie/rooms/schlafplatz.JPG",
    thumbnail: "/static-images/galerie/rooms/schlafplatz_thumbnail.JPG",
    alt: "Schlafplatz",
    width: 1080,
    height: 1619,
  },
  {
    src: "/static-images/galerie/rooms/saal_von_rechts.JPG",
    thumbnail: "/static-images/galerie/rooms/saal_von_rechts_thumbnail.JPG",
    alt: "Saal von rechts",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/theke.JPG",
    thumbnail: "/static-images/galerie/rooms/theke_thumbnail.JPG",
    alt: "Theke",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/theke_saal.JPG",
    thumbnail: "/static-images/galerie/rooms/theke_saal_thumbnail.JPG",
    alt: "Theke Saal",
    width: 1619,
    height: 1080,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_1.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_1_thumbnail.JPG",
    alt: "Biergarten 1",
    width: 851,
    height: 568,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_2.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_2_thumbnail.JPG",
    alt: "Biergarten 2",
    width: 851,
    height: 568,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_3.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_3_thumbnail.JPG",
    alt: "Biergarten 3",
    width: 851,
    height: 568,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_4.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_4_thumbnail.JPG",
    alt: "Biergarten 4",
    width: 851,
    height: 568,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_5.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_5_thumbnail.JPG",
    alt: "Biergarten 5",
    width: 851,
    height: 568,
  },
  {
    src: "/static-images/galerie/rooms/biergarten_6.JPG",
    thumbnail: "/static-images/galerie/rooms/biergarten_6_thumbnail.JPG",
    alt: "Biergarten 6",
    width: 851,
    height: 568,
  },
];
