import React from "react";

// css
import styles from "./MonthList.module.css";

// components
import EventItem from "../eventItem/EventItem";
import { SubHeadline } from "../../../Headlines";

const MonthList = (props) => {
  return (
    <div className={styles.mainContainer}>
      <SubHeadline
        title={props.month_year}
        level="1"
        position="left"
        leftSpace="1.2rem"
        bottomMargin="1rem"
        border={true}
        fontSize="2.5rem"
      />

      {props.events.map((event) => (
        <EventItem event={event} key={event.title + event.start_date} />
      ))}
    </div>
  );
};

export default MonthList;
