import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// css
import styles from "./CustomCarousel.module.css";
import "./Carousel.css";

const CustomCarousel = (props) => {
  // image paths
  const [imagePaths] = useState(
    (props.imagePaths || []).map((imagePath, i) => ({
      src: i === 0 ? imagePath : null,
      dataSrc: imagePath,
    }))
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const updateSrcIfNeeded = (index) => {
    if (!imagePaths[index].src) {
      imagePaths[index].src = imagePaths[index].dataSrc;
    }
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === imagePaths.length - 1 ? 0 : activeIndex + 1;
    updateSrcIfNeeded(nextIndex);
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? imagePaths.length - 1 : activeIndex - 1;
    updateSrcIfNeeded(nextIndex);
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    updateSrcIfNeeded(newIndex);
    setActiveIndex(newIndex);
  };

  const slides = imagePaths.map((imagePath, index) => {
    return (
      <CarouselItem
        pause="hover"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={imagePath.dataSrc}
        className={styles.carouselItem}
      >
        <img
          src={imagePath.src}
          alt={imagePath.src}
          className={`${styles.carouselImage}`}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      fade={true}
      interval={props.interval}
      className={styles.carousel}
      ride="carousel"
    >
      <CarouselIndicators
        items={imagePaths}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default CustomCarousel;
