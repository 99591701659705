import React from "react";

// css
import styles from "./AboutImage.module.css";

const AboutImage = (props) => {
  return (
    <div className={styles.aboutImageContainer}>
      {/* image */}
      <img src={props.image} alt={props.image} className={styles.image} />
      {/* description */}
      <div className={styles.descriptionContainer}>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default AboutImage;
