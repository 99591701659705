import React from "react";

// css
import "./Container.css";

// components
import CustomCarousel from "../../carousel/CustomCarousel";
import { ArrowButton } from "../../buttons/Buttons";
import { SubHeadline } from "../../Headlines";

const imagePaths = [
  "/static-images/homepage/about/1.jpg",
  "/static-images/homepage/about/2.jpg",
  "/static-images/homepage/about/3.jpg",
  "/static-images/homepage/about/4.jpg",
  "/static-images/homepage/about/5.jpg",
  "/static-images/homepage/about/6.jpg",
  "/static-images/homepage/about/7.jpg",
];

const FourthContainer = (props) => {
  return (
    <div className="mainContainer">
      <div className="contentContainer no_border right-text top-text">
        <div className="carouselContainer">
          <CustomCarousel imagePaths={imagePaths} interval="18000" />
        </div>
        {/* title */}
        <div className="textContainer">
          <SubHeadline
            title="Von der Ruine zum Liveclub"
            level="1"
            position="center"
            leftSpace="1.2rem"
          />
          <p className="text">
            Am Anfang stand der Wunsch eine Musikkneipe in Forst zu eröffnen.
            Dann folgte die Suche nach einem geeigneten Gebäude. Nach mehreren
            Absagen erhielten wir nach einem dreijährigen Kampf mit der Treuhand
            den Zuschlag für einen geschichtsträchtigen Sportplatz mit
            abrissreifem Gebäude.
          </p>
          <p className="text">
            Frohen Mutes und voller Tatendrang krempelten wir die Ärmel hoch und
            legten einfach los. Das Ganze natürlich immer nach Feierabend
            unserer regulären Arbeit und am Wochenende. Nach weiteren drei
            Jahren konnten wir mit einem großen Rockfestival das MANITU
            eröffnen. Und wiederum drei Jahre später hatten wir genug Geld
            gespart, um den bisher provisorisch genutzten Saal im Stil der
            zuerst fertiggestellten Gaststätte umzubauen. Das neuste Projekt ist
            nun der Außenbereich, der im selben Ambiente das Ganze abschließt.
          </p>
          <br />
          <div className="buttonContainer">
            <ArrowButton
              url="/about"
              value="Unsere Story lesen"
              dark={true}
              width={"15rem"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthContainer;
