import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavbarText,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NavigationItem from "./NavigationItem";

// css
import styles from "./Header.module.css";

// images
// import mainImage from "../../static-images/homepage.jpg";

const HomepageHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [background, setBackground] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const showBackground = () => {
    if (window.scrollY >= window.innerHeight / 2) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", showBackground);
  }, []);

  return (
    <div>
      <Navbar
        expand="lg"
        container="lg"
        className={`${styles.navbar} ${
          background || isOpen
            ? styles.navbarBackground
            : styles.navbarNoBackground
        }`}
        fixed="top"
        scrolling="true"
      >
        <NavbarBrand href="/" className={`${styles.brand}`}>
          {background ? (
            <img
              alt="Manitu Logo"
              src={"/static-images/logo_white.png"}
              height="83"
              width="130"
            />
          ) : (
            <img
              alt="Manitu Logo"
              src={"/static-images/logo_black.png"}
              height="83"
              width="130"
            />
          )}
        </NavbarBrand>
        <NavbarToggler
          onClick={toggle}
          className={`styles.navbarToggler`}
          style={{ backgroundColor: "white" }}
        />
        <Collapse isOpen={isOpen} navbar className={styles.collapse}>
          <Nav className={styles.nav} navbar>
            <NavigationItem link="/" text="Home" />
            <NavigationItem link="/program" text="Programm" />
            <NavigationItem link="/bands" text="Bands" />
            <NavigationItem link="/events" text="Event Vielfalt" />
            <NavigationItem link="/gallery" text="Galerie" />
            <NavigationItem link="/about" text="Unsere Story" />
            <NavigationItem link="/contact" text="Kontakt" />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default HomepageHeader;
