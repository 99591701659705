import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

// css
import styles from "./FAQSection.module.css";

const FAQSection = () => {
  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion className={styles.accordion} flush open={open} toggle={toggle}>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="1">
          Gibt es bei euch Übernachtungsmöglichkeiten?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="1">
          Nein, bei uns direkt nicht aber wir haben in Forst viele Pensionen und
          ein gutes Hotel ganz in der Nähe. Bei Bedarf können wir Ihnen auch mit
          Kontaktdaten weiterhelfen.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="2">
          Kann man bei euch Zelten?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="2">
          Ja das ist möglich.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="3">
          Darf man in der Nähe vom Club parken?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="3">
          Genau gegenüber des MANITU befindet sich ein großer Parkplatz. Dort
          können auch Wohnmobile über Nacht stehen. Auf der Straße besteht kein
          Parkverbot, dort darf man sein Auto ebenfalls parken.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="4">
          Bekommt man bei euch auch ein Taxi?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="4">
          Oft stehen Taxis vor dem Club. Wenn nicht können wir verschiedene
          Unternehmen rufen. Ab 2.00 Uhr wird es inzwischen aber etwas
          schwierig. Daher sollte in diesem Fall vorher ein Taxi bestellt
          werden.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="5">
          Kann man vor oder während des Konzerts bei uns etwas essen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="5">
          Selbstverständlich halten wir für den Konzertabend eine kleine
          Speisekarte bereit. So sind jederzeit z.B. Schnitzel mit Pommes,
          Bauernfrühstück, Bratkartoffeln, Bockwurst, Schmalzbrote,
          hausgemachter Burger, Kartoffelecken, Strammer Max oder eine
          Tagessuppe im Angebot.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="6">
          Wann wird nach dem Konzert geschlossen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="6">
          Manchmal finden nach dem Konzert die besten Partys statt. Oft stehen
          danach auch die Musiker noch gern an der Theke. Solange noch
          fröhliches Treiben herrscht, bleibt der Laden offen!
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="7">
          Wie kommt man an Karten?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="7">
          Der einfachste Weg ist unter: 03562665274 anzurufen oder unter:
          info@manitu-forst.de eine kurze Mail zu schicken. Dann reservieren wir
          für Sie die Karten. Bei manchen Konzerten haben wir einen Ticketlink
          über unseren Eventim Ticketshop geschalten.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="8">
          Bekommt man bei euch am nächsten Tag ein Frühstück?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="8">
          Für Gäste, die zelten oder im Auto übernachten, machen wir nach
          Absprache selbstverständlich auch am nächsten Tag ein Frühstück.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="9">
          Kann man bei euch ein Plakat der spielenden Band bekommen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="9">
          In den meisten Fällen halten wir einige Plakate für unsere Gäste
          bereit. Es braucht also niemand die Plakate vom Tor abzureißen und die
          Reißzwecken rumzuwerfen.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="10">
          Kann man Autogramme von den Bands bekommen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="10">
          Nach dem Konzert verkaufen die Bands Merchandise und geben sehr gern
          Autogramme.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="11">
          Wie sind eure täglichen Öffnungszeiten?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="11">
          Da wir uns am Rande einer Kleinstadt befinden und die Zeiten sind, wie
          sie sind, haben wir keine täglichen Öffnungszeiten mehr. Wir sind
          inzwischen eine Eventgastronomie- das heißt, wir haben nur noch zu den
          bekannten Veranstaltungen geöffnet oder nach Absprache.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="12">
          Können wir zu den Konzerten einen Hund mitbringen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="12">
          Solange der Hund sich mit einem gut sitzenden Gehörschutz wohl fühlt,
          können Sie das gerne machen. Ansonsten möchten wir Sie bitten, im
          Sinne des Hundes lieber darauf zu verzichten.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="13">
          Dürfen Kinder zu den Konzerten kommen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="13">
          Selbstverständlich - solange Sie als Erziehungsberechtigter dabei
          sind, ist das kein Problem. Denken Sie aber bitte an einen
          Gehörschutz, falls die Kinder noch etwas jünger sind.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="14">
          Muss man, wenn man nicht tanzt, Eintritt zahlen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="14">
          Bitte nicht lachen aber so etwas wird tatsächlich gefragt. Also - ja
          das muss man!
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="15">
          Wenn man nur im Gastraum sitzt und nicht durch die Tür in den Saal
          geht - muss man dann Eintritt zahlen?
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="15">
          Da Gastraum und Saal eine Einheit bilden, muss man auch in diesem Fall
          Eintritt bezahlen!
        </AccordionBody>
      </AccordionItem>
      <AccordionItem className={styles.accordionItem}>
        <AccordionHeader className={styles.accordionHeader} targetId="16">
          Dürfen wir zu unserer Familienfeier eigene Getränke mitbringen? Sie
          sparen sich ja dann die Mühe des Ausschanks?!
        </AccordionHeader>
        <AccordionBody className={styles.accordionBody} accordionId="16">
          Aus Höflichkeit möchten wir diese Fragen lieber nicht beantworten!
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default FAQSection;
