import React, { useState, useEffect } from "react";

// css
import styles from "./Quote.module.css";

// quotes
import quotes from "./quotes.json";

const Quote = () => {
  // get a random quote
  const [quote, setQuote] = useState(null);
  useEffect(() => {
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, []);

  return (
    <div className={styles.mainDiv}>
      {" "}
      {quote && (
        <>
          <div
            className={styles.quoteText}
            dangerouslySetInnerHTML={{ __html: quote.text }}
          />
          <p className={styles.p2}>- {quote.author}</p>
        </>
      )}
    </div>
    // <div className={styles.quote}>
    //   <div
    //     className={styles.quoteText}
    //     dangerouslySetInnerHTML={{ __html: quote.text }}
    //   />
    //   <p className={styles.quoteAuthor}>- {quote.author}</p>
    // </div>
  );
};

export default Quote;
