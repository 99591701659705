import React, { useEffect, useRef, useState } from "react";
import { getBands } from "../../../api_functions/bands_api";
import { CountUp } from "countup.js";
import Carousel from "react-spring-3d-carousel";

// css
import "./Container.css";
import styles from "./SecondContainer.module.css";

// components
import { ArrowButton } from "../../buttons/Buttons";
const imagePaths = [
  "/static-images/homepage/music/1.jpg",
  "/static-images/homepage/music/2.jpg",
  "/static-images/homepage/music/3.jpg",
  "/static-images/homepage/music/4.jpg",
  "/static-images/homepage/music/5.jpg",
  "/static-images/homepage/music/6.jpg",
  "/static-images/homepage/music/7.jpg",
  "/static-images/homepage/music/8.jpg",
  "/static-images/homepage/music/9.jpg",
  "/static-images/homepage/music/10.jpg",
];

const SecondContainer = (props) => {
  const [actualSlide, setActualSlide] = useState(0);

  // const [bandNumber, setBandNumber] = useState(0);
  const countUpRef = useRef(null);
  let countUpAnim;
  const countUpOptions = {
    startVal: 123,
    duration: 1.75,
    useEasing: true,
    suffix: " Bands",
    enableScrollSpy: true,
    scrollSpyOnce: true,
  };

  // get the number of bands
  useEffect(() => {
    getBands().then((data) => {
      initCountUp(data.length);
    });
    // eslint-disable-next-line
  }, []);

  const initCountUp = async (bandsCount) => {
    countUpAnim = new CountUp(countUpRef.current, bandsCount, countUpOptions);
  };

  // use a timer before going to the next slide#
  useEffect(() => {
    const timer = setTimeout(() => {
      if (actualSlide > imagePaths.length - 1) {
        setActualSlide(0);
      } else {
        setActualSlide(actualSlide + 1);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [actualSlide]);

  const slides = [
    {
      key: 1,
      content: (
        <img
          src="/static-images/homepage/music/1.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(0)}
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/static-images/homepage/music/2.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(1)}
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/static-images/homepage/music/3.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(2)}
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/static-images/homepage/music/4.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(3)}
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/static-images/homepage/music/5.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(4)}
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/static-images/homepage/music/6.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(5)}
        />
      ),
    },
    {
      key: 7,
      content: (
        <img
          src="/static-images/homepage/music/7.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(6)}
        />
      ),
    },
    {
      key: 8,
      content: (
        <img
          src="/static-images/homepage/music/8.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(7)}
        />
      ),
    },
    {
      key: 9,
      content: (
        <img
          src="/static-images/homepage/music/9.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(8)}
        />
      ),
    },
    {
      key: 10,
      content: (
        <img
          src="/static-images/homepage/music/10.jpg"
          alt="test"
          className="carousel3dImage"
          onClick={() => setActualSlide(9)}
        />
      ),
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overlay_parent}>
        <div className={styles.carouselContainer3D}>
          <Carousel
            slides={slides}
            goToSlide={actualSlide}
            style={{ paddingTop: 0 }}
          />
        </div>
        <div className={styles.bands_overlay}>
          <div className={styles.textContainer}>
            <p className="text">
              Blues, Rock, Metal, Gothic, Folk, Mittelalterrock, NHD – es ist
              für fast jeden Fan etwas dabei. Viele unserer Acts sind
              mittlerweile international bekannt und bringen somit die große,
              weite Welt nach Forst ins MANITU.
            </p>
            <span>Insgesamt haben schon mehr als</span>
            <h2 className={styles.bandCounter} ref={countUpRef}>
              129
            </h2>
            <span>im Manitu gespielt</span>
            <div className="bandCounterContainer"></div>
            <div className="buttonContainer">
              <ArrowButton
                url="/bands"
                value="Alle Bands Durchsuchen"
                dark={true}
                width={"19rem"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondContainer;
