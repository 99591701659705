import React from "react";
import { render } from "react-dom";
import { Parallax, Background } from "react-parallax";

// css
import styles from "./FullImageContainer.module.css";

// components
import { ArrowButton } from "../../buttons/Buttons";
import { SubHeadline } from "../../Headlines";

const FullImageContainer = (props) => {
  return (
    <section className={styles.fullImageSection}>
      <Parallax strength={150}>
        {/* <div className={styles.heightContainer}> */}
        <Background className="custom-bg">
          <div
            style={{
              height: 2000,
              width: 2000,
              backgroundImage: `url(${props.imagePath})`,
            }}
          />
        </Background>
        <div className={styles.flexContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.textContainer}>
              <SubHeadline
                title={props.title}
                level="1"
                position="center"
                leftSpace="0rem"
              />
              <br />
              {props.texts.map((text, index) => {
                return (
                  <p key={index} className={styles.textParagraph}>
                    {text}
                  </p>
                );
              })}
            </div>
            <div className={styles.buttonContainer}>
              <ArrowButton
                value={"Weitere Eindrücke"}
                url={"/gallery"}
                width="15rem"
              />
            </div>
          </div>
        </div>
      </Parallax>

      {/* <div
        className={styles.mainContainer}
        style={{ "--image-path": `url(${props.imagePath})` }}
      >
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <SubHeadline
              title={props.title}
              level="1"
              position="center"
              leftSpace="0rem"
            />
            <br />
            {props.texts.map((text, index) => {
              return (
                <p key={index} className={styles.textParagraph}>
                  {text}
                </p>
              );
            })}
          </div>
          <div className={styles.buttonContainer}>
            <ArrowButton
              value={"Weitere Eindrücke"}
              url={"/galerie"}
              width="15rem"
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default FullImageContainer;
