import React, { useEffect, useState, useRef } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavbarText,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NavigationItem from "./NavigationItem";

// css
import styles from "./Header.module.css";
import "./Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navItemRef = useRef();

  // useEffect(() => {
  //   window.addEventListener("click", (event) => {
  //     if (!navItemRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   });
  // }, []);

  return (
    <div>
      <Navbar
        expand="lg"
        container="xl"
        className={`${styles.navbar} ${styles.navbarBackground}`}
        fixed="top"
        ref={navItemRef}
      >
        <NavbarBrand className={styles.brand} href="/">
          <img
            alt="Manitu Logo"
            src={"/static-images/logo_white.png"}
            height="83"
            width="130"
          />
        </NavbarBrand>
        <NavbarToggler
          onClick={toggle}
          className={`styles.navbarToggler`}
          style={{ backgroundColor: "white" }}
        />
        <Collapse
          isOpen={isOpen}
          navbar
          style={{ width: "100vh" }}
          className={styles.collapse}
        >
          <Nav className={styles.nav} navbar>
            <NavigationItem link="/" text="Home" />
            <NavigationItem link="/program" text="Programm" />
            <NavigationItem link="/bands" text="Bands" />
            <NavigationItem link="/events" text="Event Vielfalt" />
            <NavigationItem link="/gallery" text="Galerie" />
            <NavigationItem link="/about" text="Unsere Story" />
            <NavigationItem link="/contact" text="Kontakt" />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
