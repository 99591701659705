import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Row, Col, Table, Button } from "reactstrap";
import { atcb_action, atcb_init } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

// css
import styles from "./ImageDateContainer.module.css";

const ImageDateContainer = ({ event }) => {
  const start_date = new Date(event.start_date);
  const css_day = start_date.toLocaleString("de", { weekday: "short" });
  const start_date_string = start_date.toISOString().split("T")[0];
  const start_day_string = start_date.toLocaleString("de", { day: "2-digit" });
  const start_month_year_string = start_date.toLocaleString("de", {
    month: "long",
    year: "numeric",
  });
  const start_time_string = start_date.toLocaleString("de", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const [begin_hours, begin_minutes, begin_seconds] = event.begin
    ? event.begin.split(":")
    : [null, null, null];
  const begin = event.begin
    ? new Date(1998, 8, 1, +begin_hours, +begin_minutes, +begin_seconds)
    : null;
  const begin_time_string = begin
    ? begin.toLocaleString("de", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;
  const end_date = event.end_date ? new Date(event.end_date) : null;
  const end_date_string = end_date
    ? end_date.toLocaleString("de", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    : null;
  const end_time_string = end_date
    ? end_date.toLocaleString("de", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;

  const calendar_form = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          atcb_action({
            name: event.title,
            description: event.description,
            startDate: start_date_string,
            startTime: start_time_string,
            endTime: "23:59",
            location: "Frankfurter Straße 135, 03149 Forst Lausitz",
            options: [
              "Apple",
              "Google",
              "iCal",
              "Microsoft365",
              "Outlook.com",
              "Yahoo",
            ],
            timeZone: "Europe/Berlin",
            iCalFileName: "Reminder-Event",
            language: "de",
          });
        }}
      >
        <button
          className={`atcb-button ${styles.buttonElement} ${
            styles.calendarButton
          } ${event.status === "CAN" ? styles.butCancelled : styles.butActive}`}
          type="submit"
          // deactivate the button if the event is cancelled
          disabled={event.status === "CAN" ? true : false}
        >
          <img
            className={styles.icon}
            src="/static-images/calendar.svg"
            alt="tickets"
          />
          <span>Add to Calendar</span>
        </button>
      </form>
    );
  };

  return (
    <Row className={styles.row}>
      {/* image */}
      <Col xs="12" lg="9" className={`${styles.imageCol} ${styles.col}`}>
        <div
          className={styles.imageContainer}
          style={{ "--image-path": `url(${event.image})` }}
        ></div>
      </Col>
      {/* additional information */}
      <Col xs="12" lg="3" className={`${styles.col}`}>
        <div className={styles.infoContainer}>
          {/* week */}
          <div className={styles.week}>
            <span className={`${styles.mo} ${styles[css_day]}`}>Mo</span>
            <span className={`${styles.di} ${styles[css_day]}`}>Di</span>
            <span className={`${styles.mi} ${styles[css_day]}`}>Mi</span>
            <span className={`${styles.do} ${styles[css_day]}`}>Do</span>
            <span className={`${styles.fr} ${styles[css_day]}`}>Fr</span>
            <span className={`${styles.sa} ${styles[css_day]}`}>Sa</span>
            <span className={`${styles.so} ${styles[css_day]}`}>So</span>
          </div>
          {/* start date */}
          <div className={`${styles.dateContainer}`}>
            <span
              className={`${styles.start_day} ${
                event.status === "CAN" && styles.cancelled
              }`}
            >
              {start_day_string}.
            </span>
            <span
              className={`${styles.start_month_year} ${
                event.status === "CAN" && styles.cancelled
              }`}
            >
              {start_month_year_string}
            </span>
          </div>
          {/* times */}
          <Table
            className={`${styles.timesTable} ${
              event.status === "CAN" && styles.cancelled
            }`}
          >
            <tbody>
              {begin_time_string && (
                <tr className={styles.tableRow}>
                  <td className={styles.timeLabel}>Beginn:</td>
                  <td className={styles.time}>{begin_time_string}</td>
                </tr>
              )}
              <tr className={styles.tableRow}>
                <td className={styles.timeLabel}>
                  <span className={styles.timeLabel}>Einlass:</span>
                </td>
                <td className={styles.time}>{start_time_string}</td>
              </tr>
              {/* {end_date_string && (
                <tr>
                  <td className={styles.timeLabel}>Ende:</td>
                  <td className={styles.time}>{end_time_string}</td>
                </tr>
              )} */}
            </tbody>
          </Table>

          {/* optional: band page link(s) */}
          {/* if bands exists iterate over all bands */}
          {event.bands.length > 0 && (
            <>
              {event.bands.map((band) => (
                <div className={styles.bandLinkContainer} key={band.url}>
                  {/* link icon */}
                  <div className={styles.icon}>
                    <img src="/static-images/link.svg" alt="link" />
                  </div>
                  <a
                    href={band.url}
                    className={styles.bandLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {band.name}
                  </a>
                </div>
              ))}
            </>
          )}

          <div className={styles.LinkButtonsDiv}>
            {/* optional: ticket link with tickets icon */}
            {event.ticket_url ? (
              <Row className={styles.ticketRow} xs="12">
                <a
                  className={`${
                    event.status === "CAN"
                      ? styles.disabledLink
                      : styles.ticketLink
                  }`}
                  href={event.ticket_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className={`atcb-button ${styles.buttonElement} ${
                      styles.linkButton
                    }  ${
                      event.status === "CAN"
                        ? styles.butCancelled
                        : styles.butActive
                    }`}
                    disabled={event.status === "CAN" ? true : false}
                  >
                    <img
                      className={styles.icon}
                      src="/static-images/tickets.svg"
                      alt="tickets"
                    />
                    <span>Tickets kaufen</span>
                  </button>
                </a>
              </Row>
            ) : (
              <Row className={styles.ticketRow} xs="12">
                <HashLink
                  smooth
                  to="#Kontakt"
                  className={styles.ticketLink}
                  disabled={event.status === "CAN" ? true : false}
                >
                  <button
                    className={`atcb-button ${styles.buttonElement} ${
                      styles.linkButton
                    } ${
                      event.status === "CAN"
                        ? styles.butCancelled
                        : styles.butActive
                    }`}
                    disabled={event.status === "CAN" ? true : false}
                  >
                    <img
                      className={styles.icon}
                      src="/static-images/email.svg"
                      alt="contact"
                    />
                    <span>Reservieren</span>
                  </button>
                </HashLink>
              </Row>
            )}

            {/* facebook link */}
            {event.facebook_url ? (
              <Row className={styles.facebookCalendarRow}>
                <Col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="12"
                  className={styles.facebookColf}
                >
                  <a
                    className={`${
                      event.status === "CAN"
                        ? styles.disabledLink
                        : styles.ticketLink
                    }`}
                    href={event.facebook_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={`atcb-button ${styles.buttonElement} ${
                        styles.facebookButton
                      } ${
                        event.status === "CAN"
                          ? styles.butCancelled
                          : styles.butActive
                      }`}
                    >
                      <img
                        className={styles.icon}
                        src="/static-images/facebook.svg"
                        alt="facebook"
                      />
                      <span>Facebook</span>
                    </button>
                  </a>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="12"
                  className={styles.facebookColc}
                >
                  {calendar_form()}
                </Col>
              </Row>
            ) : (
              <Row className={styles.facebookCalendarRow}>
                <Col xs="12" className={styles.calendarCol}>
                  {calendar_form()}
                </Col>
              </Row>
            )}
          </div>

          {/* add to calender stuff */}
          {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                atcb_action({
                  name: event.title,
                  description: event.description,
                  startDate: start_date_string,
                  startTime: start_time_string,
                  endTime: "23:59",
                  location: "Frankfurter Straße 135, 03149 Forst Lausitz",
                  options: [
                    "Apple",
                    "Google",
                    "iCal",
                    "Microsoft365",
                    "Outlook.com",
                    "Yahoo",
                  ],
                  timeZone: "Europe/Berlin",
                  iCalFileName: "Reminder-Event",
                  language: "de",
                });
              }}
            >
              <button
                className={`atcb-button ${styles.linkButton}`}
                type="submit"
              >
                <img
                  className={styles.icon}
                  src="/static-images/calendar.svg"
                  alt="tickets"
                />
                <span>Add to Calendar</span>
              </button>
            </form>
          </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default ImageDateContainer;
