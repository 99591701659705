import React, { useState, useEffect, useRef } from "react";

// css
import "./Container.css";

// components
import CustomCarousel from "../../carousel/CustomCarousel";
import { ArrowButton, DoubleButton } from "../../buttons/Buttons";
import ListItem from "../../events/listItem/ListItem";
import { SubHeadline } from "../../Headlines";

const imagePaths = [
  "/static-images/homepage/events/1.jpg",
  "/static-images/homepage/events/2.jpg",
  "/static-images/homepage/events/3.jpg",
  "/static-images/homepage/events/4.jpg",
  "/static-images/homepage/events/5.jpg",
];

const ThirdContainer = (props) => {
  const carouselRef = useRef(null);

  // useEffect(() => {
  //   if (carouselRef.current) {
  //     const textContainerHeight =
  //       document.getElementById("textContainer").offsetHeight;
  //     // check max screen width
  //     if (window.innerWidth > 900) {
  //       carouselRef.current.style.maxHeight = textContainerHeight + "px";
  //       console.log(textContainerHeight);
  //     } else {
  //       carouselRef.current.style.maxHeight = "none";
  //     }
  //   }
  //   // trigger when window size changes
  // }, [window.innerWidth]);

  return (
    <div className="mainContainer dark">
      <div className="contentContainer no_border left-text top-text">
        <div ref={carouselRef} className="carouselContainer">
          <CustomCarousel imagePaths={imagePaths} interval="200000" />
        </div>
        {/* title */}
        <div className="textContainer" id="textContainer">
          {/* <h1 className="title">Veranstaltungen für jeden Geschmack</h1> */}
          <SubHeadline
            title="Veranstaltungen für jeden Geschmack"
            level="1"
            position="center"
            leftSpace="1.2rem"
          />
          <p className="text">
            So bunt wie das Leben sind auch die Veranstaltungen im MANITU! Wir
            schlagen den Bogen von der OLDIE-Disco über die Country- und
            Linedance Party bis zum Tattoo Wochenende. Oder Sie lassen mal so
            richtig die Sau raus beim großen Ritterbankett. Sehr beliebt sind
            natürlich auch die „Whisky-Lounge-Abende“, der Musiker Stammtisch
            oder die musikalischen Clubabende. Oder wie wäre es mit einer
            Motorrad-Ausfahrt, Lesungen, US-Car & Rockabilly Treffen oder der
            schwarzen Rocknacht?! Eigentlich ist das Jahr viel zu kurz, um alle
            Ideen und Vorhaben umzusetzen. Aber wir geben uns die größte Mühe,
            Ihnen jedes Jahr ein umfangreiches Programm zu präsentieren.
          </p>
          {/* <p>
            Weit über die Grenzen der Stadt sind unsere zwei großen Festivals
            „BLUES-MESSE“ und „WIGWAM GETÖSE“ bekannt. Auch das große
            Ritterbankett mit Erlebnisprogramm ist immer schnell ausgebucht.
          </p> */}
          {/* und das einzigartige
            Ambiente  */}
          {/* So sorgen wir dafür, dass Ihre Feier in
            unserem Haus ein unvergessliches Erlebnis wird. */}
          <p>
            Natürlich können Sie die einmalige Atmosphäre des MANITU auch für
            private Familienfeiern wie Geburtstage, Hochzeiten oder
            Betriebsfeiern nutzen. Mit unserer jahrelangen Erfahrung
            unterstützen wir Sie bei der Beratung, Planung und Durchführung
            Ihrer Feier und bieten Ihnen aus unserer Küche umfangreiche Buffets
            ganz nach Ihren Wünschen.
          </p>
          <br />
          <div className="buttonContainer">
            <ArrowButton
              url="/events"
              value="Weitere Informationen"
              dark={false}
              width={"17rem"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdContainer;
