import React from "react";
import { NavLink } from "reactstrap";

// components
import { Col } from "reactstrap";

// css
import styles from "./ListItem.module.css";

const ListItem = (props) => {
  const date = new Date(props.event.start_date);
  const dateString = date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  return (
    <Col lg="6" md="6" sm="6" xs="12">
      <NavLink href={`/event/${props.event.event_url}`}>
        <div className={styles.listItem}>
          <p className={styles.itemDate}>{dateString}</p>
          <p className={styles.itemTitle}>{props.event.title}</p>
          {/* <div className={styles.overlay}>
            <p className={styles.hover_text}>Click Me</p>
          </div> */}
        </div>
      </NavLink>
    </Col>
  );
};

export default ListItem;
