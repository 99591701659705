import React, { useState, useEffect } from "react";

// css
import styles from "./Events.module.css";

// api
import { getEvents } from "../../api_functions/events_api";

// components
import MonthList from "../../components/events/eventLists/monthList/MonthList";

const Events = () => {
  const [monthLists, setMonthLists] = useState([]);

  useEffect(() => {
    getEvents(true, false).then((res) => {
      // go through events and collect all events from same month
      var month_year = "";
      var month_events = [];
      var month_lists = [];
      res.forEach((event) => {
        const date = new Date(event.start_date);
        var tmp_month_year = date.toLocaleString("de", {
          month: "long",
          year: "numeric",
        });
        if (month_events.length === 0) {
          month_year = tmp_month_year;
        } else if (month_year !== tmp_month_year) {
          month_lists.push({ month_year: month_year, events: month_events });
          month_events = [];
          month_year = tmp_month_year;
        }
        month_events.push(event);
      });
      month_lists.push({ month_year: month_year, events: month_events });
      setMonthLists(month_lists);
    });
  }, []);

  return (
    <div className={`${styles.mainContainer} contentContainer`}>
      {monthLists.map((monthList) => (
        <MonthList
          events={monthList.events}
          month_year={monthList.month_year}
          key={monthList.month_year}
        />
      ))}
    </div>
  );
};

export default Events;
