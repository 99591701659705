import React from "react";

import styles from "./Spacer.module.css";

const Spacer = (props) => {
    const backgroundColor = props.backgroundColor || "none";
    const height = props.height || "1rem";

    return (
        <div
            className={styles.spacer}
            style={{ backgroundColor: backgroundColor, height: height }}
        ></div>
    );
};

export default Spacer;
